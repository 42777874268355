<script setup>
  import { ref, reactive, watch } from "vue"
  import Datepicker from '@vuepic/vue-datepicker'
  import Api from "@/services/api/ApiServiceFabrick"
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import { ObjectToFormData } from '@/services/functions'
  import { GDialog } from 'gitart-vue-dialog'
  import { format } from 'date-fns'
  import { ja } from 'date-fns/locale'
  import i18n from '@/lang/i18n';

  const props = defineProps({
    modalState: Boolean,
    title: String,
    selectItem: Object,
    startDateTime: String,
    endDateTime: String,
  })

  const emits = defineEmits(['closeModal', 'apply'])

  const state = ref(false)
  const absoluteTabName = i18n.global.t('pages.temperatureSensors.charts.modal.specifyPeriod')
  const relativeTabName = i18n.global.t('pages.temperatureSensors.charts.modal.specifyStartDate')
  const absoluteNotes = i18n.global.t('pages.temperatureSensors.charts.modal.selectPeriod')
  const relativeNotes = i18n.global.t('pages.temperatureSensors.charts.modal.selectStartDate')
  const modalMaxWidth = '50%'
  const applyBtnName = i18n.global.t('pages.temperatureSensors.charts.button.apply')
  const closeBtnName = i18n.global.t('pages.common.button.close')
  const closeBtnClass = 'mt-0'
  const applyBtnClass = 'exec-btn mt-0 ml-1'
  const cancelText = i18n.global.t('pages.temperatureSensors.charts.modal.cancel')
  const selectText = i18n.global.t('pages.temperatureSensors.charts.modal.select')
  const absolute = reactive({
    startDateTime: new Date().setDate(new Date().getDate() - 1),
    endDateTime: new Date()
  })
  const relative = reactive({
    startDateTime: new Date().setDate(new Date().getDate() - 1)
  })
  const selectItem = ref("absolute")
  const selectableRange = ref([])
  const errors = ref([])
  const startAndEndTimes = reactive({
    selectItem: String,
    startTime: String,
    endTime: String
  })

  watch(() => props.startDateTime, () => {
    if (props.startDateTime) {
      absolute.startDateTime = format(props.startDateTime, 'yyyy-MM-dd HH:mm:ss', { locale: ja })
      startAndEndTimes.startTime = absolute.startDateTime
    }
  })

  watch(() => props.endDateTime, () => {
    if (props.endDateTime) {
      absolute.endDateTime = format(props.endDateTime, 'yyyy-MM-dd HH:mm:ss', { locale: ja })
      startAndEndTimes.endTime = absolute.endDateTime
    }
  })

  function close () {
    absolute.startDateTime = startAndEndTimes.selectItem == "absolute" && startAndEndTimes.startTime ? startAndEndTimes.startTime : new Date().setDate(new Date().getDate() - 1)
    absolute.endDateTime = startAndEndTimes.selectItem == "absolute" && startAndEndTimes.endTime ? startAndEndTimes.endTime : new Date()
    relative.startDateTime = startAndEndTimes.selectItem == "relative" && startAndEndTimes.startTime ? startAndEndTimes.startTime : new Date().setDate(new Date().getDate() - 1)
    emits("closeModal", false)
  }

  function select (tabItem) {
    selectItem.value = tabItem
    errors.value = []
    if (selectItem.value == "relative") {
      for (var i = 0; i <= 7; i++) {
        selectableRange.value.push(new Date(new Date().setDate(new Date().getDate() - i)))
      }
    }
  }

  function apply () {
    startAndEndTimes.selectItem = selectItem.value

    if (selectItem.value == "absolute") {
      startAndEndTimes.startTime = new Date(absolute.startDateTime)
      startAndEndTimes.endTime = new Date(absolute.endDateTime)
    } else {
      startAndEndTimes.startTime = new Date(relative.startDateTime)
      startAndEndTimes.endTime = new Date()
    }

    if (validations(startAndEndTimes)) emits('apply', startAndEndTimes)
  }

  function validations (startAndEndTimes) {
    errors.value = []
    const startUnixTime = new Date(startAndEndTimes.startTime).getTime()
    const endUnixTime = new Date(startAndEndTimes.endTime).getTime()
    const diffDays = parseInt((endUnixTime - startUnixTime) / 24 / 60 / 60 / 1000)
    if (diffDays > 7) {
      errors.value.push(i18n.global.t('pages.temperatureSensors.charts.errors.selectWithin7Days'))
      return false
    }
    if (endUnixTime - startUnixTime < 0 ) {
      errors.value.push(i18n.global.t('pages.temperatureSensors.charts.errors.inputStartAndEnd'))
      return false
    }
    return true
  }

  watch(absolute, () => {
    errors.value = []
  })
  watch(relative, () => {
    errors.value = []
  })
  watch(() => props.modalState, (value) => {
    state.value = value
    if (state.value) return
  })
</script>

<template>
  <GDialog
    v-if="state"
    v-model="state"
    :max-width="modalMaxWidth"
    transition="custom-from-bottom-transition"
    :persistent='true'
  >
    <div class="modal-header justify-content-center mt-2">
      <label>{{ title }}</label>
    </div>

    <div class="modal-body mx-2">

        <div class="tabnav d-flex justify-content-between w-90 mx-3 mt-3">
          <div class="p-2 tab-item absolute w-50 d-flex justify-center" @click="select('absolute')" :class="{'active': selectItem == 'absolute'}">
            {{absoluteTabName}}
          </div>
          <div class="p-2 tab-item relative w-50 d-flex justify-center" @click="select('relative')" :class="{'active': selectItem == 'relative'}">
            {{relativeTabName}}
          </div>
        </div>
        <div class="tabcontent">
          <div v-if="selectItem == 'absolute'" class="tabcontent-list">
            <div class="m-3">{{ absoluteNotes }}</div>
            <div class="pt-3 pb-3 d-flex justify-content-center">
              <Datepicker
                class="mr-2"
                style="width:45%;"
                locale="jp"
                :cancel-text="cancelText"
                :select-text="selectText"
                v-model="absolute.startDateTime"
                format="yyyy/MM/dd HH:mm:ss"
              />
              <p class="my-auto"> ~ </p>
              <Datepicker
                class="ml-2"
                style="width:45%;"
                locale="jp"
                :cancel-text="cancelText"
                :select-text="selectText"
                v-model="absolute.endDateTime"
                format="yyyy/MM/dd HH:mm:ss"
              />
            </div>
            <div v-for="error in errors" :key="error" class="d-flex justify-content-center">
              <p class="validation-error">{{ error }}</p>
            </div>
          </div>
          <div v-else-if="selectItem == 'relative'" class="tabcontent-list">
            <div class="m-3">{{ relativeNotes }}</div>
            <div class="col-12 pt-3 pb-3 d-flex justify-content-center">
              <Datepicker
                class="col-5"
                locale="jp"
                :cancel-text="cancelText"
                :select-text="selectText"
                v-model="relative.startDateTime"
                format="yyyy/MM/dd HH:mm:ss"
                :allowed-dates="selectableRange"
              />
              <div class="ml-3 my-auto">
                〜  {{ i18n.global.t('pages.temperatureSensors.charts.modal.currentTime') }}
              </div>
            </div>
            <div v-for="error in errors" :key="error" class="d-flex justify-content-center">
              <p class="validation-error">{{ error }}</p>
            </div>
          </div>
        </div>
      <div class="d-flex justify-content-end m-3 mt-2">
        <v-btn :class="closeBtnClass" class="close-btn" @click="close" :disabled="uploading">
          {{ closeBtnName }}
        </v-btn>

        <v-btn :class="applyBtnClass" class="file-upload-btn" @click="apply" :loading="uploading">
          {{ applyBtnName }}
        </v-btn>
      </div>
    </div>
  </GDialog>
</template>

<style scoped>
  .upload-file-results p {
    margin-bottom: 0px !important;
  }
  .upload-file-area .v-messages__message {
    color: rgb(var(--v-theme-error)) !important;
  }
  .tabnav {
    border-radius: 5px 5px 0 0;
  }
  .tabnav .tab-item {
    cursor: pointer;
    background: #ddd;
    color: #333;
  }
  .tabnav .absolute {
    border-radius: 5px 0 0 5px;
  }
  .tabnav .relative {
    border-radius: 0 5px 5px 0;
  }
  .tabnav .tab-item.active {
    background: #3388DC;
    color: #fff;
  }
  .validation-error {
    color: red;
    font-size: 80%;
  }
</style>
