<script setup>
import Api from '@/services/api/ApiServiceFabrick';
import ManagementOfficeAutoComplete from '@/components/AutoCompletes/ManagementOfficeAutoComplete';
import SingleOperationOfficeAutoComplete from '@/components/AutoCompletes/SingleOperationOfficeAutoComplete';
import VehicleAutoComplete from '@/components/AutoCompletes/VehicleAutoComplete.vue';
import { reactive, ref, onMounted, watch, computed } from 'vue';
import i18n from '@/lang/i18n';

const props = defineProps({
  windowInnerWidth: Number,
  pdfLoading: Boolean,
  loading: Boolean,
  vehicleId: String,
  showableButton: Boolean,
  showFlash: Function,
});

const emits = defineEmits([
  'search',
  'update',
  'vehicleSelectedState',
  'updateIsQueryParams',
]);
const selectItems = reactive({
  managementOffice: null,
  operationOffice: null,
  vehicle: null,
});
const disabledBtn = ref(true);
const notDeviceRegistered = ref([]);
const searchBtnLabel = ref(i18n.global.t('pages.temperatureSensors.charts.button.search'));
const isSubManagementOffice = ref(false);
const isManagementOffice = ref(false);

onMounted(() => {
  Api().getSelfAccount().then((data) => {
    isSubManagementOffice.value = data.login_organization_type == 'App\\Models\\SubManagementOffice'
    isManagementOffice.value = data.login_organization_type == 'App\\Models\\ManagementOffice'
  })
  if (props.vehicleId) {
    Api()
      .getVehicle(props.vehicleId)
      .then((data) => {
        selectItems.vehicle = { id: data.id };
      })
      .catch((error) => {
        const res = error.response;
        switch (res.status) {
          case 403:
            emits('updateIsQueryParams', false);
            props.showFlash(i18n.global.t('pages.temperatureSensors.charts.errors.getData'), 'error', true);
            break;
          case 422:
            emits('updateIsQueryParams', false);
            props.showFlash(i18n.global.t('pages.temperatureSensors.charts.errors.getData'), 'error', true);
        }
      });
  }
});

watch(
  () => selectItems.vehicle,
  (newValue) => {
    selectVehicle(newValue);
  }
);

const managementOfficeClearable = computed(() => {
  return isManagementOffice.value;
})

const operationOfficeClearable = computed(() => {
  return isManagementOffice.value || isSubManagementOffice.value;
})

function handleClickSearch() {
  emits('search', selectItems);
}

function selectVehicle(vehicle) {
  disabledBtn.value = false;
  if (selectItems?.vehicle == null) {
    emits('vehicleSelectedState', false);
    disabledBtn.value = true;
  }
  notDeviceRegistered.value = [];
  if (selectItems?.vehicle?.digital_tacograph === null) {
    disabledBtn.value = true;
    notDeviceRegistered.value.push(i18n.global.t('pages.temperatureSensors.charts.errors.notDeviceRegistered'));
  }
  emits('update', selectItems);
}
</script>

<template>
  <div class="select-office mb-4 col-12">
    <v-row class="d-flex">
      <v-col :cols="windowInnerWidth > 800 ? 4 : 8">
        <ManagementOfficeAutoComplete
          v-model="selectItems.managementOffice"
          selectFirstIfOnlyOne
          :clearable="managementOfficeClearable"
        />
      </v-col>
      <v-col :cols="windowInnerWidth > 800 ? 4 : 8">
        <SingleOperationOfficeAutoComplete
          v-model="selectItems.operationOffice"
          :managementOffice="selectItems.managementOffice"
          selectFirstIfOnlyOne
          :clearable="operationOfficeClearable"
        />
      </v-col>
      <v-col :cols="windowInnerWidth > 800 ? 4 : 8">
        <VehicleAutoComplete
          v-model="selectItems.vehicle"
          :operationOffices="
            selectItems.operationOffice ? [selectItems.operationOffice] : []
          "
          :error-messages="notDeviceRegistered"
        />
      </v-col>
    </v-row>
  </div>
  <div class="mb-6 d-flex justify-end align-end" v-show="props.showableButton">
    <v-btn
      class="exec-btn"
      @click="handleClickSearch"
      :disabled="disabledBtn || pdfLoading"
      :loading="props.loading && !pdfLoading"
      :style="{
        backgroundColor: disabledBtn || pdfLoading ? '#B3B8BB' : '',
      }"
    >
      {{ searchBtnLabel }}
    </v-btn>
  </div>
</template>

<style></style>
