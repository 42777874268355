<template>
  <div class="monthly-report-output-layout pt-3 pb-3">
    <v-row class="mb-1">
      <v-col>
        <v-select
          class="small"
          v-model="selectedReportType"
          :items="displayReportTypes"
          :label="i18n.global.t('pages.safeDrivingReports.outputLayout.ledger')"
          :clearable="false"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-text-field
          v-model="monthlyScore"
          :label="monthlyScoreLabel"
          :prefix="highlightSettingsPrefix"
          :suffix="highlightSettingsSufix"
          type="number"
          clearable
          class="small"
        >
        </v-text-field>
      </v-col>
      <v-col v-show="showableDailyhighlightSettings">
        <v-text-field
          v-model="dailyScore"
          :label="dailyScoreLabel"
          :prefix="highlightSettingsPrefix"
          :suffix="highlightSettingsSufix"
          type="number"
          clearable
          class="small"
        >
        </v-text-field>
      </v-col>
      <v-col v-show="showableSortOrder">
        <v-select
          class="small"
          v-model="selectedSortOrder"
          :items="displaySortOrders"
          :label="i18n.global.t('pages.safeDrivingReports.outputLayout.driverOrder')"
        >
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import i18n from '@/lang/i18n';

export default {
  setup() {
    return {
      i18n
    }
  },
  data() {
    return {
      outputSettings: {},
      displayReportTypes: [],
      displaySortOrders: [],
      showableSortOrder: false,
      showableDailyhighlightSettings: true,
      reportTypes: [
        {
          key: 'report1',
          label: i18n.global.t('pages.safeDrivingReports.outputLayout.safeDrivingActualResult'),
          data: 'SafeDrivingActualResult',
        },
        {
          key: 'report2',
          label: i18n.global.t('pages.safeDrivingReports.outputLayout.safeDrivingReport'),
          data: 'SafeDrivingReport',
        },
        {
          key: 'report3',
          label: i18n.global.t('pages.safeDrivingReports.outputLayout.fuelEconomy'),
          data: 'FuelEconomy'
        },
        {
          key: 'report4',
          label: i18n.global.t('pages.safeDrivingReports.outputLayout.drivingScore'),
          data: 'DrivingScore'
        },
      ],
      highlightSettings: {
        SafeDrivingActualResult: {
          monthlyLabel: i18n.global.t('pages.safeDrivingReports.outputLayout.emphasisMonthlyDrivingScore'),
          dailyLabel: i18n.global.t('pages.safeDrivingReports.outputLayout.emphasisDailyDrivingScore'),
          prefix: i18n.global.t('pages.safeDrivingReports.outputLayout.emphasisScore'),
          sufix: i18n.global.t('pages.safeDrivingReports.outputLayout.less'),
        },
        SafeDrivingReport: {
          monthlyLabel: i18n.global.t('pages.safeDrivingReports.outputLayout.emphasisMonthlyDrivingScore'),
          dailyLabel: '',
          prefix: i18n.global.t('pages.safeDrivingReports.outputLayout.emphasisScore'),
          sufix: i18n.global.t('pages.safeDrivingReports.outputLayout.less'),
        },
        FuelEconomy: {
          monthlyLabel: i18n.global.t('pages.safeDrivingReports.outputLayout.emphasisMonthlyFuelEconomy'),
          dailyLabel: i18n.global.t('pages.safeDrivingReports.outputLayout.emphasisDailyFuelEconomy'),
          prefix: i18n.global.t('pages.safeDrivingReports.outputLayout.fuelEconomy'),
          sufix: i18n.global.t('pages.safeDrivingReports.outputLayout.unitLess'),
        },
        DrivingScore: {
          monthlyLabel: i18n.global.t('pages.safeDrivingReports.outputLayout.emphasisMonthlyDrivingScore'),
          dailyLabel: i18n.global.t('pages.safeDrivingReports.outputLayout.emphasisDailyDrivingScore'),
          prefix: i18n.global.t('pages.safeDrivingReports.outputLayout.drivingScore'),
          sufix: i18n.global.t('pages.safeDrivingReports.outputLayout.pointLess'),
        },
      },
      sortOrderLists: {
        FuelEconomy: [
          {
            key: 'sort1',
            label: i18n.global.t('pages.safeDrivingReports.outputLayout.registerDate'),
            data: 'created_at_desc'
          },
          {
            key: 'sort2',
            label: i18n.global.t('pages.safeDrivingReports.outputLayout.fuelEconomyDesc'),
            data: 'score_desc'
          },
          {
            key: 'sort3',
            label: i18n.global.t('pages.safeDrivingReports.outputLayout.fuelEconomyAsc'),
            data: 'score_asc'
          },
        ],
        DrivingScore: [
          {
            key: 'sort1',
            label: i18n.global.t('pages.safeDrivingReports.outputLayout.registerDate'),
            data: 'created_at_desc'
          },
          {
            key: 'sort2',
            label: i18n.global.t('pages.safeDrivingReports.outputLayout.drivingScoreDesc'),
            data: 'score_desc'
          },
          {
            key: 'sort3',
            label: i18n.global.t('pages.safeDrivingReports.outputLayout.drivingScoreAsc'),
            data: 'score_asc'
          },
        ],
      },
      selectedReportType: null,
      selectedSortOrder: null,
      monthlyScore: 0,
      dailyScore: 0,
      monthlyScoreLabel: null,
      dailyScoreLabel: null,
      highlightSettingsPrefix: null,
      highlightSettingsSufix: null,
    };
  },
  created() {
    const reportType = this.reportTypes[0].data;
    this.selectedReportType = this.reportTypes[0].label;

    this.setDisplayReportTypes();
    this.setMonthlyAndDailyLabel(reportType);
  },
  methods: {
    makeReportTypeName(reportType) {
      return (
        'App\\Services\\OutputReports\\Monthly\\Drivers\\' +
        reportType +
        '\\PdfCreateService'
      );
    },
    setDisplayReportTypes() {
      this.displayReportTypes = this.reportTypes.map((v) => {
        return v.label;
      });
    },
    setDisplaySortOrders(sortOrderList) {
      this.displaySortOrders = sortOrderList
        ? sortOrderList.map((v) => {
            return v.label;
          })
        : [];
    },
    setMonthlyAndDailyLabel(reportType) {
      const highlightSetting = this.highlightSettings[reportType];
      this.monthlyScoreLabel = highlightSetting.monthlyLabel;
      this.dailyScoreLabel = highlightSetting.dailyLabel;
      this.highlightSettingsPrefix = highlightSetting.prefix;
      this.highlightSettingsSufix = highlightSetting.sufix;
    },
    changeOutputSettings() {
      this.$emit('change', this.outputSettings);
    },
  },
  watch: {
    selectedReportType: {
      handler() {
        // reset report type
        const selectedReportType = this.reportTypes.find(
          (v) => v.label == this.selectedReportType
        );
        this.outputSettings.type = this.makeReportTypeName(
          selectedReportType.data
        );

        // reset sort order
        const sortOrderList = this.sortOrderLists[selectedReportType.data];
        if (sortOrderList) {
          this.selectedSortOrder = sortOrderList[0].label;
          this.outputSettings.sort = sortOrderList[0].data;
          this.showableSortOrder = true;
        } else {
          this.selectedSortOrder = null;
          this.outputSettings.sort = null;
          this.showableSortOrder = false;
        }
        this.setDisplaySortOrders(sortOrderList);

        // set 0 for monthly and daily score
        this.monthlyScore = 0;
        this.outputSettings.require_monthly_score = 0;
        this.dailyScore = 0;
        this.outputSettings.require_daily_score = 0;

        // reset monthly and daily label
        this.setMonthlyAndDailyLabel(selectedReportType.data);
        this.showableDailyhighlightSettings =
          selectedReportType.data != this.reportTypes[1]['data'];

        this.changeOutputSettings();
      },
    },
    monthlyScore: {
      handler(value) {
        if (value == '') value = 0;
        this.outputSettings.require_monthly_score = value;
        this.changeOutputSettings();
      },
    },
    dailyScore: {
      handler(value) {
        if (value == '') value = 0;
        this.outputSettings.require_daily_score = value;
        this.changeOutputSettings();
      },
    },
    selectedSortOrder: {
      handler(value, preValue) {
        if (!value || !preValue) return;

        const selectedReportType = this.reportTypes.find(
          (v) => v.label == this.selectedReportType
        ).data;
        const sortOrderList = this.sortOrderLists[selectedReportType].find(
          (v) => v.label == value
        );
        this.outputSettings.sort = sortOrderList.data;

        this.changeOutputSettings();
      },
    },
  },
};
</script>