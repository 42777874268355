<template>
  <div class="d-flex justify-content-end bd-highlight mb-3 output-button-area">
    <v-btn
      class="exec-btn"
      :disabled="!downloadable"
      @click="handleClickOutputPdf"
      :style="{
        backgroundColor : downloading ? '#B3B8BB' : ''
      }"
    >
      {{downloadBtnLabel}}
    </v-btn>
  </div>
</template>

<script>
  import Api from "@/services/api/ApiServiceFabrick";
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import i18n from '@/lang/i18n';

  export default {
    setup() {
      const { loginWithRedirect } = useAuth();
      return {
        loginWithRedirect,
        i18n
      }
    },
    props: {
      operationOfficeId: null,
      monthly: null,
      driverIds: [],
      outputSettings: {},
    },
    data() {
      return {
        downloading: false,
      }
    },
    computed: {
      downloadable() {
        return this.driverIds.length && !this.downloading
      },
      downloadBtnLabel() {
        return this.downloading ?
          i18n.global.t('pages.safeDrivingReports.button.downloading'):
          i18n.global.t('pages.safeDrivingReports.button.download');
      },
    },
    methods: {
      handleClickOutputPdf() {
        this.downloading = true
        const params = this.makeCreateFileRequest()
        Api().createJobStatus(params).then((data) => {
          this.pollingJobStatus(data.id)
        }).catch((error) => {
          const res = error.response
          switch (res.status) {
            case 401:
              this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
              break
            case 403:
              location.reload()
              break
            case 422:
          }
          this.downloading = false
        })
      },
      pollingJobStatus(id) {
        const interval = setInterval(() => {
          Api().getJobStatus(id).then((res) => {
            if(res.status == "completed") {
              this.download(res.file_path)
              clearInterval(interval)
              this.downloading = false
            }
            if (res.status == "failed") {
              alert(res.output_contents.message)
              clearInterval(interval)
              this.downloading = false
            }
          })
        }, 3000)
      },
      download(path) {
        Api().fileDownload(path)
      },
      makeCreateFileRequest() {
        const params = Object.assign({}, this.outputSettings)
        const joinDriverIds = this.driverIds.join(',')
        params['operation_office_id'] = this.operationOfficeId
        params['monthly'] = this.monthly
        params['driver_id'] = joinDriverIds

        return params
      },
    },
  }

</script>

<style>
  .output-button-area .v-btn--disabled.v-btn--variant-contained {
    background-color: rgb(71, 170, 196) !important;
  }
</style>
