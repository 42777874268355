
export const JOB_STATUS_SUCCESS = 'completed';
export const JOB_STATUS_FAILED = 'failed';
export const JOB_STATUS_WAITING = 'waiting';
export const JOB_STATUS_READY = 'ready';
export const JOB_STATUS_WORKING = 'working';

export const JOB_STATUS_FINISHED_STATUSES = [
  JOB_STATUS_SUCCESS,
  JOB_STATUS_FAILED,
]

