export default {
  title: "月報出力",
  outputConditions: "出力条件",
  driver: "ドライバー",
  vehicle: "車両",
  messages: {
    displayCurrentMonth: "当月を表示しています"
  },
  list: {
    all: "ドライバー or 車両",
    inputWorkingTimes: "出社・退社時間入力",
  },
  searchForm: {
    aggregationTarget: "集計対象",
    monthly: "月分"
  },
  button: {
    download: "ダウンロード",
    downloading: "ダウンロード中..."
  },
  outputLayout: {
    ledger: "帳票",
    format: "フォーマット",
    byDriverPreview: "乗務員別労働実績明細表",
    laborSummaryTable: "乗務員別労働実績一覧表",
    noticeExceeding: "改善基準告示超過表",
    byVehiclePreview: "車両別稼働実績明細表",
    vehicleSummaryTable: "車両別稼働実績一覧表"
  }
}
