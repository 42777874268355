<template>
  <div class="d-flex" style="min-height: 45vw;">
    <div class="content p-4 w-100">
      <div class="d-flex">
        <h4 class="card-title mt-0">
          {{ i18n.global.t('pages.safeDrivingReports.title') }}
        </h4>
        <p class="ml-5 pb-1 mb-1 align-self-end operation-office-initial-announce"
          v-show="operationOfficeInitialMessage && beforeSearchParams == null">
          {{ i18n.global.t('pages.safeDrivingReports.displayCurrentMonth') }}
        </p>
        <div class="ml-auto ml-2 mb-1 align-self-end">
          <ManualLink :pageName="pageName" />
        </div>
      </div>
      <div>
        <SafeDrivingReportDriverList
          :pageInfo="pageInfo"
          :searched="searched"
          :items="driverList"
          :notReady="notReady"
          :isOperationOffice="isOperationOffice"
          :isDepartment="isDepartment"
          :pagenate="searchDrivers"
          :listLoading="listLoading"
          @select="handleSelectDriverIds"
        />
        <pagenate
          :range="2"
          :totalPage="totalPage"
          v-model="currentPage"
        />
        <hr />
      </div>
      <h4>
        {{ i18n.global.t('pages.safeDrivingReports.outputConditions') }}
      </h4>
      <div>
        <SafeDrivingReportOutputLayout @change="setOutputSettings"/>
        <div v-show="showableButtonArea">
          <ButtonArea
            :operationOfficeId="operationOfficeId"
            :monthly="monthly"
            :driverIds="selectedDriverIds"
            :outputSettings="selectOutputSettings"
          />
        </div>
      </div>
    </div>

    <SafeDrivingReportSearchForm
      :loading="listLoading"
      :isOperationOffice="isOperationOffice"
      :isSubManagementOffice="isSubManagementOffice"
      :isManagementOffice="isManagementOffice"
      :isDepartment="isDepartment"
      @formchange="handleChangeSerarchForm"
      @search="searchDrivers"
    />
  </div>
</template>

<script>
  import { MANAGEMENT_OFFICE_TYPE, SUB_MANAGEMENT_OFFICE_TYPE, OPERATION_OFFICE_TYPE, DEPARTMENT_OFFICE_TYPE } from "@/services/consts.ts"
  import SafeDrivingReportSearchForm from "@/components/SafeDrivingReportSearchForm"
  import SafeDrivingReportDriverList from "@/components/SafeDrivingReportDriverList"
  import SafeDrivingReportOutputLayout from "@/components/SafeDrivingReportOutputLayout"
  import ButtonArea from "@/components/SafeDrivingReportOutputButtonArea"
  import Api from "@/services/api/ApiServiceFabrick";
  import PagenateItems from "@/components/PagenateItems"
  import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
  import ManualLink from "@/components/ManualLink";
  import {ref} from 'vue'
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import i18n from '@/lang/i18n';

  export default {
    props: {
      switchContentLoading: {
        type: Function
      }
    },
    data() {
      return {
        form: {},
        currentPage: 1,
        totalPage: 0,
        selectedDriverIds: [],
        listLoading: false,
        searched: false,
        beforeSearchParams: null,
        isOperationOffice: false,
        isSubManagementOffice: false,
        isManagementOffice: false,
        isDepartment: false,
        operationOfficeInitialMessage: false,
        notReady: true,
        pageName: "safeDrivingReport"
      }
    },
    computed: {
      getMonthly() {
        const monthIndex = this.form?.selectYearMonth?.month
        const month = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'][monthIndex]
        return this.form?.selectYearMonth?.year + '/' + month
      }
    },
    setup() {
      document.title = i18n.global.t('pages.safeDrivingReports.documentTitle')

      const showableButtonArea = ref(true)
      const setShowableButtonArea = async () => {
        const authorityList = await AuthorityCacheApi.getWithParse()
        showableButtonArea.value = authorityList["octlink.safe_driving_report.output"]
      }
      setShowableButtonArea()

      const { loginWithRedirect } = useAuth();

      return {
        showableButtonArea,
        loginWithRedirect,
        i18n
      }
    },
    mounted() {
      this.switchContentLoading(true)
      Api().getSelfAccount().then((data) => {
        this.isManagementOffice = data.login_organization_type == MANAGEMENT_OFFICE_TYPE
        this.isSubManagementOffice = data.login_organization_type == SUB_MANAGEMENT_OFFICE_TYPE
        this.isOperationOffice = data.login_organization_type == OPERATION_OFFICE_TYPE
        this.isDepartment = data.login_organization_type == DEPARTMENT_OFFICE_TYPE
        this.switchContentLoading(false)
        this.notReady = false
        if (this.isOperationOffice || this.isDepartment) return this.getInitialSafeDrivingReport()
      })
    },
    watch: {
      currentPage: {
        handler() {
          this.getDrivers(this.currentPage, false)
        }
      }
    },
    methods: {
      async getInitialSafeDrivingReport() {
        this.listLoading = true
        let selfDepartment
        let selfOperationOffice
        if (this.isDepartment) {
          selfDepartment = await this.getDepartment()
          selfOperationOffice = await this.getOperationOffice(selfDepartment[0].operationOfficeId)
        } else {
          selfOperationOffice = await this.getSelfOffice()
        }
        const request = this.makeInitialParams(selfOperationOffice, selfDepartment)
        this.apiGetSafeDrivingReports(request)
      },
      async getDepartment() {
        let department
        await Api().autoCompleteDepartments().then((data) => {
          department = data
        })
        return department
      },
      async getSelfOffice() {
        let selfOffice
        await Api().getSelfOffice().then((data) => {
          selfOffice = data
        })
        return selfOffice
      },
      async getOperationOffice(operationOfficeId) {
        let operationOffice
        await Api().getOperationOffice(operationOfficeId).then((data) => {
          operationOffice = data
        })
        return operationOffice
      },
      getOperationOfficeInitialMonthlyReport(account, operationoffice) {
        let request = this.OperationOfficeInitialMakeParams(account, operationoffice)
        this.apiGetMonthlyReports(request)
      },
      makeInitialParams(operationoffice, departments = []) {
        const department = departments.length == 1 ? departments[0] : null

        return {
          page: 1,
          operation_office_id: operationoffice?.id,
          management_office_id: operationoffice?.management_officeable_id,
          management_officeable_type: operationoffice?.management_officeable_type,
          department_id: department?.id,
          monthly: this.getMonthly,
          type: 'driver',
          authority_name: 'drivingReportView'
        }
      },
      handleChangeSerarchForm(form) {
        this.form = form
      },
      setOutputSettings(settings) {
        this.selectOutputSettings = settings
      },
      handleSelectDriverIds(selectedDriverIds) {
        this.selectedDriverIds = selectedDriverIds
      },
      searchDrivers() {
        this.operationOfficeInitialMessage = false
        this.searched = true
        this.getDrivers()
      },
      getDrivers(page = 1, updateOption = true) {
        this.listLoading = true
        let req
        if (updateOption || !this.beforeSearchParams) {
          req = this.makeSearchParams(page)
          this.beforeSearchParams = req
        } else {
          req = this.beforeSearchParams
          req.page = page
        }
        this.apiGetSafeDrivingReports(req)
      },
      apiGetSafeDrivingReports(req) {
        Api().getMonthlyReports(req).then((data) => {
          this.driverList = data.items
          this.currentPage = data.current_page
          this.totalPage = data.total_page
          this.listLoading = false
          this.operationOfficeId = this.form.operationOffice?.id
          this.monthly = this.getMonthly
          this.operationOfficeInitialMessage = true
        }).catch((error) => {
          const res = error.response
          switch (res.status) {
            case 401:
              this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
              break
            case 403:
              location.reload()
              break
            case 422:
          }
          this.listLoading = false
        })
      },
      makeSearchParams(page){
        const monthly = this.getMonthly
        return {
          page: page,
          operation_office_id: this.form?.operationOffice?.id,
          management_office_id: this.form?.managementOffice?.id,
          management_officeable_type: this.form?.managementOffice?.type,
          group_id: this.form?.group?.id,
          department_id: this.form?.department?.id,
          monthly: monthly,
          type: 'driver',
          driver_id: this.form?.driver?.driver_id,
          account_id: this.form?.driver?.id,
          authority_name: 'drivingReportView'
        }
      },
    },
    components: {
      SafeDrivingReportSearchForm,
      SafeDrivingReportDriverList,
      SafeDrivingReportOutputLayout,
      ButtonArea,
      pagenate: PagenateItems,
      ManualLink
    }
  }
</script>

<style>
.main-panel {
  height: initial !important;
  max-height: initial !important;
}
.exec-btn {
  background-color: rgb(21, 130, 156);
  color: white !important;
}
.reset-btn {
  background-color: rgb(245, 246, 247);
  color: rgb(21, 130, 156) !important;
  font-weight: bold;
}
.operation-office-initial-announce {
  font-size: 10px
}

</style>
