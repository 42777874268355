import {sortType} from "@/services/consts";

export enum sortField {
  ORDER_BY_DEVICE_ID = 'device_id',
  ORDER_BY_OPERATION_OFFICE = 'operation_office',
  ORDER_BY_DEVICE_TYPE = 'device_type',
}

export enum perPage {
  ITEM_50 = 50,
  ITEM_100 = 100,
  ITEM_200 = 200,
}

export type getDeviceFirmwareListRequest = {
  version?: string,
  device_id?: number,
  ar_device_type_ids?: number[],
  device_operation_office_companies?: number[],
  firmware_id?: number,
  vehicle_id?: number,
  page?: number,
  per_page?: perPage,
  sort_type?: sortType,
  sort?: sortField,
  activation_date_range_start: string,
  activation_date_range_end: string,
}
