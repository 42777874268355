import ApiProvider from "@/services/api/providers/ApiProvider"
import AutoComplete from '@/services/api/providers/AutoCompletes/DummyApiProvider';

import getManagementOfficeResponse from "@/types/responses/getManagementOffice"
import getOperationOfficeResponse from "@/types/responses/getOperationOffice"
import getPaginatedOperationOfficesRequest from "@/types/requests/getPaginatedOperationOffices"
import getPaginatedOperationOfficesResponse from "@/types/responses/getPaginatedOperationOffices"
import getApiOperationOfficeResponse from "@/types/apiresponses/getApiOperationOffice"
import createOrUpdateOperationOffice from "@/types/requests/createOrUpdateOperationOffice"
import updateOperationOfficeStatus from "@/types/requests/updateOperationOfficeStatus"
import getOperationOfficeWithAssociationResponse from "@/types/responses/getOperationOfficeWithAssociation"
import updateOperationOfficeRequest from "@/types/requests/updateOperationOffice"
import updateReportSettingsRequest from "@/types/requests/updateReportSettings"
import getPagenatedInstallationOfficesRequest from "@/types/requests/getPagenatedInstallationOffices"
import getPagenatedInstallationOfficesResponse from "@/types/responses/getPagenatedInstallationOffices"
import createOrUpdateInstallationOffice from "@/types/requests/createOrUpdateInstallationOffice"
import getInstallationOffice from "@/types/responses/getInstallationOffice"
import updateInstallationOfficeStatus from "@/types/requests/updateInstallationOfficeStatus"
import getGroupResponse from "@/types/responses/getGroup"
import getPaginatedGroupRequest from "@/types/requests/getPaginatedGroups"
import getPaginatedGroupResponse from "@/types/responses/getPaginatedGroups"
import getDepartmentResponse from "@/types/responses/getDepartment"
import getApiDepartmentResponse from "@/types/apiresponses/getApiDepartment"
import getVehicleResponse from "@/types/responses/getVehicle"
import getVehicleFormResponse from "@/types/responses/getVehicleForm"
import createVehicleRequest from "@/types/requests/createVehicle"
import searchVehicleRequest from "@/types/requests/searchVehicle"
import searchVehicleResponse from "@/types/responses/searchVehicle"
import getAccountResponse from "@/types/responses/getAccount"
import getPaginatedAccountRequest from "@/types/requests/getPaginatedAccounts"
import getPaginatedAccountResponse from "@/types/responses/getPaginatedAccounts"
import getManagementOfficeAccountsResponse from "@/types/responses/getManagementOfficeAccounts"
import getAuthorityResponse from "@/types/responses/getAuthority"
import getDailyReportResponse from "@/types/responses/getDailyReport"
import getDailyReportRequest from "@/types/requests/getDailyReport"
import createJobStatusRequest from "@/types/requests/createJobStatus"
import createJobStatusResponse from "@/types/apiresponses/createJobStatus"
import DeliveryPlan from '@/types/DeliveryPlan'
import DeliveryPlanWorkType from "@/types/DeliveryPlanWork"
import getLongTimeContinuousAlertResponse from "@/types/responses/getLongTimeContinuousAlert"
import getLongTimeContinuousAlertRequest from "@/types/requests/getLongTimeContinuousAlert"
import updateLongTimeContinuousAlertRequest from "@/types/requests/updateLongTimeContinuousAlert"
import getDailyReportLayoutResponse from "@/types/responses/getDailyReportLayout"
import searchDailyReportLayoutRequest from "@/types/requests/searchDailyReportLayout"
import createDailyReportLayoutRequest from "@/types/requests/createDailyReportLayout"
import getMonthlyReportResponse from "@/types/responses/getMonthlyReport"
import getMonthlyReportRequest from "@/types/requests/getMonthlyReport"
import getDeliveryPlanWorkResponse from "@/types/responses/getDeliveryPlanWork"
import getDrivingRecordsResponse from "@/types/responses/getDrivingRecords"
import getActualVehicleRatioTransitionsResponse from '@/types/responses/getActualVehicleRatioTransition'
import getSafetyDrivingScoreTransitionsResponse from '@/types/responses/getSafetyDrivingScoreTransition'
import getRestraintAndWorkingTimesResponse from '@/types/responses/getRestraintAndWorkingTime'
import getSpotResponse from "@/types/responses/getSpot"
import getSpotRequest from "@/types/requests/getSpot"
import SelfAccount from "@/types/SelfAccount"
import SelfOffice from "@/types/SelfOffice"
import SelfRole from "@/types/SelfRole"
import {getFirmwareListRequest} from "@/types/requests/getFirmwareListRequest";
import getFirmwareListResponse from "@/types/responses/getFirmwareListResponse";
import getFirmwareVersionsResponse from "@/types/responses/getFirmwareVersionsResponse";
import getFirmwareRequest from "@/types/requests/getFirmwareRequest";
import getFirmwareResponse from "@/types/responses/getFirmwareResponse";
import updateFirmwareRequest from "@/types/requests/updateFirmwareRequest";
import createFirmwareRequest from "@/types/requests/createFirmwareRequest";
import {getDeviceFirmwareListRequest} from "@/types/requests/getDeviceFirmwareListRequest";
import getDeviceFirmwareListResponse from "@/types/responses/getDeviceFirmwareListResponse";
import geDeviceIdsResponse from "@/types/responses/getDeviceIdsResponse";
import {attachFirmwareToDevicesRequest} from "@/types/requests/attachFirmwareToDevicesRequest";
import {detachFirmwareFromDevicesRequest} from "@/types/requests/detachFirmwareFromDevicesRequest";
import getOfficesResponse from "@/types/responses/getOffices"
import createOrUpdateAccountRequest from "@/types/requests/createOrUpdateAccount"
import createOrUpdateGroupRequest from "@/types/requests/createOrUpdateGroup"
import createCvrUploadRequest from "@/types/requests/createCvrUpload"
import getCvrUploadHoldResponse from "@/types/responses/getCvrUploadHold";
import getPagenatedCvrUploadResponse from "@/types/responses/getPagenatedCvrUpload"
import getCvrUploadResponse from "@/types/responses/getCvrUpload"
import CvrUpload from "@/types/CvrUpload";
import createOrUpdateDeliveryPlanWorkingTimeRequest from "@/types/requests/createOrUpdateDeliveryPlanWorkingTime"
import getNoticesResponse from "@/types/responses/getNotices";
import getDeviceResponse from "@/types/responses/getDevice"
import createOrUpdateDevice from "@/types/requests/createOrUpdateDevice"
import getPaginatedDevicesRequest from "@/types/requests/getPaginatedDevices"
import getPaginatedDevicesResponse from "@/types/responses/getPaginatedDevices"
import updateStatus from "@/types/requests/updateStatus"
import getMakersResponse from "@/types/responses/getMakers"
import getAllowAccessOfficeResponse from "@/types/responses/getAllowAccessOffice"
import createAllowAccessOffice from "@/types/requests/createAllowAccessOffice"
import getDeliveryPlanDrivingStoppingPeriodResponse from "@/types/responses/getDeliveryPlanDrivingStoppingPeriod"
import getWorkloadResponse from "@/types/responses/getWorkload"
import WorkloadType from "@/types/Workload"
import CvrUploadPeriod from "@/types/responses/getCvrUploadPeriod"
import getTemperatureInformationVehicle from "@/types/requests/getTemperatureInformationVehicle"
import temperatureInformationSpotRequest from "@/types/requests/temperatureInformationSpot"
import temperatureInformationSpotResponse from "@/types/responses/temperatureInformationSpot"
import latestSensorsResponse from "@/types/responses/getLatestSensors"
import latestSensorsRequest from "@/types/requests/getLatestSensors"
import getPagenatedSubManagementOffice from "@/types/responses/getPagenatedSubManagementOffice"
import getSubManagementOffice from "@/types/requests/getSubManagementOffice"
import createOrUpdateSubManagementOffice from "@/types/requests/createOrUpdateSubManagementOffice"
import subManagementOffice from "@/types/subManagementOffice"
import getCvrUploadSummariesResponse from "@/types/responses/getCvrUploadSummaries"
import getCvrUploadSummariesRequest from "@/types/requests/getCvrUploadSummaries"
import cvrUploadSummariesData from "@/types/responses/cvrUploadSummariesData"
import createOrUpdateUnexpectedEvent from "@/types/requests/createOrUpdateUnexpectedEvent"
import getUnexpectedEvent from "@/types/responses/getUnexpectedEvent"
import getUnexpectedEvents from "@/types/responses/getUnexpectedEvents"
import getPagenatedUnexpectedEventAndLongTimeDrivingExtensions from "@/types/responses/getPagenatedUnexpectedEventAndLongTimeContinuousDrivingExtensions"
import getUnexpectedEventAndLongTimeDrivingExtensions from "@/types/requests/getUnexpectedEventAndLongTimeContinuousDrivingExtensions"
import getRole from '@/types/responses/getRole'
import getRoles from '@/types/responses/getRoles'
import createOrUpdateRole from '@/types/requests/createOrUpdateRole'
import getPagenateDepartment from  "@/types/responses/getPagenateDepartment"
import getDepartmentAllParams from "@/types/responses/getDepartmentAllParams"
import getDepartment from "@/types/requests/getDepartment"
import createOrUpdateDepartment from "@/types/requests/createOrUpdateDepartment"
import autocompleteDepartmentRequest from "@/types/requests/autocompleteDepartment"
import getPermissionListRequestType from '@/types/requests/getPermissionList'
import getPermissionListResponseType from "@/types/responses/getPermissionList"
import getRoleTemplateResponseType from '@/types/responses/getRoleTemplate'
import getPaginateUserRolesResponseType from "@/types/responses/getPaginateUserRoles"
import getUserRoleFormResponseType from "@/types/responses/getUserRoleForm"
import getUserRolesRequestType from "@/types/requests/getUserRoles"
import upsertUserRoleFormRequestType from "@/types/requests/upsertUserRoleForm";
import getRoleAssignAccountsRequest from "@/types/requests/getRoleAssignAccounts"
import getRoleAssignAccountsResponse from "@/types/responses/getRoleAssignAccountsResponse"
import updateAllowExternalShared from "@/types/requests/updateAllowExternalShared"
import getCvrUploadsType from "@/types/requests/getCvrUploads"
import getRoleAssinedAccountsRequest from "@/types/requests/getRoleAssinedAccounts"
import getRoleAssignedAccountResponseType from "@/types/responses/getRoleAssignedAccount"

class DummyApiProvider implements ApiProvider {
  public autoComplete = new AutoComplete();

  public autoCompleteManagementOffices():Promise<getManagementOfficeResponse[]> {
    return new Promise((resolve) => {
      resolve([]);
    })
  }
  public autoCompleteOperationOffices():Promise<getOperationOfficeResponse[]> {
    return new Promise((resolve) => {
      resolve([]);
    })
  }
  public async getPaginatedOperationOffices(request: getPaginatedOperationOfficesRequest):Promise<getPaginatedOperationOfficesResponse> {
    return new Promise((resolve) => {
      resolve({} as getPaginatedOperationOfficesResponse);
    })
  }
  public getOperationOffice():Promise<getOperationOfficeResponse> {
    return new Promise((resolve) => {
      resolve({} as getOperationOfficeResponse);
    })
  }
  public async createOperationOffice(request: createOrUpdateOperationOffice):Promise<getOperationOfficeResponse> {
    return new Promise((resolve) => {
      resolve({} as getOperationOfficeResponse);
    })
  }
  public async updateOperationOffice(id:number, request: createOrUpdateOperationOffice):Promise<getOperationOfficeResponse> {
    return new Promise((resolve) => {
      resolve({} as getOperationOfficeResponse);
    })
  }
  public updateOperationOfficeStatus(id: number, request: updateOperationOfficeStatus): Promise<updateOperationOfficeStatus> {
    return new Promise((resolve) => {
      resolve({} as updateOperationOfficeStatus);
    })
  }
  public async updateAllowExternalShared(id: number, request: updateOperationOfficeStatus):Promise<updateAllowExternalShared> {
    return new Promise((resolve) => {
      resolve({} as updateAllowExternalShared);
    })
  }
  public async getInstallationOffice():Promise<getInstallationOffice> {
    return new Promise((resolve) => {
      resolve({} as getInstallationOffice);
    })
  }
  public async getPaginatedInstallationOffices(request: getPagenatedInstallationOfficesRequest):Promise<getPagenatedInstallationOfficesResponse> {
    return new Promise((resolve) => {
      resolve({} as getPagenatedInstallationOfficesResponse);
    })
  }
  public async createInstallationOffice(request: createOrUpdateInstallationOffice):Promise<getInstallationOffice> {
    return new Promise((resolve) => {
      resolve({} as getInstallationOffice);
    })
  }
  public async updateInstallationOffice(id:number, request: createOrUpdateInstallationOffice):Promise<getInstallationOffice> {
    return new Promise((resolve) => {
      resolve({} as getInstallationOffice);
    })
  }
  public updateInstallationOfficeStatus(id: number, request: updateInstallationOfficeStatus): Promise<getPagenatedInstallationOfficesResponse> {
    return new Promise((resolve) => {
      resolve({} as getPagenatedInstallationOfficesResponse);
    })
  }
  public autoCompleteGroups():Promise<getGroupResponse[]> {
    return new Promise((resolve) => {
      resolve([]);
    })
  }
  public async getPaginatedGroups(request: getPaginatedGroupRequest):Promise<getPaginatedGroupResponse> {
    return new Promise((resolve) => {
      resolve({} as getPaginatedGroupResponse);
    })
  }
  public getGroup(id:number):Promise<getGroupResponse> {
    return new Promise((resolve) => {
      resolve({} as getGroupResponse);
    })
  }
  public async createGroup(request: createOrUpdateGroupRequest):Promise<getGroupResponse> {
    return new Promise((resolve) => {
      resolve({} as getGroupResponse)
    })
  }
  public async updateGroup(request: createOrUpdateGroupRequest):Promise<getGroupResponse> {
    return new Promise((resolve) => {
      resolve({} as getGroupResponse)
    })
  }
  public async updateGroupStatus(request: createOrUpdateGroupRequest):Promise<getGroupResponse> {
    return new Promise((resolve) => {
      resolve({} as getGroupResponse)
    })
  }
  public autoCompleteDepartments(request: autocompleteDepartmentRequest | undefined = undefined):Promise<getDepartmentResponse[]> {
    return new Promise((resolve) => {
      resolve([]);
    })
  }
  public getVehicle(id: number):Promise<getVehicleResponse> {
    return new Promise((resolve) => {
      resolve({} as getVehicleResponse);
    })
  }
  public getVehicleForm():Promise<getVehicleFormResponse> {
    return new Promise((resolve) => {
      resolve({} as getVehicleFormResponse);
    })
  }
  public async updateVehicleStatus(id: number, status: boolean):Promise<boolean> {
    return new Promise((resolve) => {
      resolve(true);
    })
  }
  public async createOrUpdateVehicle(id: null|number, request: createVehicleRequest):Promise<getVehicleResponse> {
    return new Promise((resolve) => {
      resolve({} as getVehicleResponse);
    })
  }
  public async searchVehicles(param: searchVehicleRequest | getTemperatureInformationVehicle):Promise<searchVehicleResponse> {
    return new Promise((resolve) => {
      resolve({} as searchVehicleResponse);
    })
  }
  public async getVehicleIniFile(id: number) {
    console.log('download inifile.')
  }
  public autoCompleteAccounts():Promise<getAccountResponse[]> {
    return new Promise((resolve) => {
      resolve([]);
    })
  }
  public getAccount(id:number):Promise<getAccountResponse> {
    return new Promise((resolve) => {
      resolve({} as getAccountResponse);
    })
  }
  public async getDrivers(params: any):Promise<getAccountResponse[]> {
    return new Promise((resolve) => {
      resolve([] as getAccountResponse[]);
    })
  }
  public async getPaginatedAccounts(_: getPaginatedAccountRequest):Promise<getPaginatedAccountResponse> {
    return new Promise((resolve) => {
      resolve({} as getPaginatedAccountResponse);
    })
  }
  public async createAccount(request: createOrUpdateAccountRequest):Promise<getAccountResponse> {
    return new Promise((resolve) => {
      resolve({} as getAccountResponse)
    })
  }
  public async updateAccount(request: createOrUpdateAccountRequest):Promise<getAccountResponse> {
    return new Promise((resolve) => {
      resolve({} as getAccountResponse)
    })
  }
  public async deleteAccount(request: createOrUpdateAccountRequest):Promise<void> {
    return new Promise((resolve) => {
      resolve()
    })
  }
  public async updateAccountStatus(request: createOrUpdateAccountRequest):Promise<getAccountResponse> {
    return new Promise((resolve) => {
      resolve({} as getAccountResponse)
    })
  }
  public getDailyReports(_:getDailyReportRequest):Promise<getDailyReportResponse> {
    return new Promise((resolve) => {
      resolve({} as getDailyReportResponse);
    })
  }
  public getJobStatus(_:number):Promise<createJobStatusResponse> {
    return new Promise((resolve) => {
      resolve({} as createJobStatusResponse);
    })
  }
  public createJobStatus(_:createJobStatusRequest | FormData):Promise<createJobStatusResponse> {
    return new Promise((resolve) => {
      resolve({} as createJobStatusResponse);
    })
  }
  public async getFileUrl(modelName: string, modelId: number):Promise<string> {
    return new Promise((resolve) => {
      resolve('https://jp.vuejs.org/images/logo.svg');
    })
  }
  public async fileDownload(path: string) {
    console.log(`download ${path}`)
  }
  public async csvTemplatefileDownload(type: string) {
    console.log(`download ${type}.csv`)
  }
  public async getDailyReportLayouts(_:searchDailyReportLayoutRequest):Promise<getDailyReportLayoutResponse> {
    return new Promise((resolve) => {
      resolve({} as getDailyReportLayoutResponse);
    })
  }
  public async createDailyReportLayout(_: createDailyReportLayoutRequest):Promise<getDailyReportLayoutResponse> {
    return new Promise((resolve) => {
      resolve({} as getDailyReportLayoutResponse);
    })
  }
  public async updateDailyReportLayout(_: createDailyReportLayoutRequest):Promise<getDailyReportLayoutResponse> {
    return new Promise((resolve) => {
      resolve({} as getDailyReportLayoutResponse);
    })
  }
  public async deleteDailyReportLayout(_: number):Promise<boolean> {
    return new Promise((resolve) => {
      resolve(true);
    })
  }
  public getMonthlyReports(_:getMonthlyReportRequest):Promise<getMonthlyReportResponse> {
    return new Promise((resolve) => {
      resolve({} as getMonthlyReportResponse);
    })
  }
  public async getDeliveryPlanWorks(_:string):Promise<getDeliveryPlanWorkResponse[]> {
    return new Promise((resolve) => {
      resolve([]);
    })
  }
  public async updateDailyReport(_:DeliveryPlan, __:DeliveryPlanWorkType[]):Promise<boolean|string> {
    return new Promise((resolve) => {
      resolve(true);
    })
  }

  public async getLongTimeContinuousAlerts(req: getLongTimeContinuousAlertRequest):Promise<getLongTimeContinuousAlertResponse[]> {
    return new Promise((resolve) => {
      resolve([]);
    })
  }
  public async updateLongTimeContinuousAlerts(request: updateLongTimeContinuousAlertRequest[]):Promise<getLongTimeContinuousAlertResponse[]> {
    return new Promise((resolve) => {
      resolve([]);
    })
  }
  public async deleteLongTimeContinuousAlerts(id: number):Promise<void> {
    return new Promise((resolve) => {
      resolve();
    })
  }

  public getDrivingStoppingPeriods(_: string): Promise<getDeliveryPlanDrivingStoppingPeriodResponse[]> {
    return new Promise((resolve) => {
      resolve([]);
    })
  }
  public getStoppingPeriodsWithSpot(_: string, __: number): Promise<getDeliveryPlanDrivingStoppingPeriodResponse[]> {
    return new Promise((resolve) => {
      resolve([]);
    })
  }
  public getWorkloads(_: number, __: string, ___: string): Promise<getWorkloadResponse> {
    return new Promise((resolve) => {
      resolve({workloads: [], selectable_workloads: [], selectable_units: []});
    })
  }
  public upsertWorkloads(_: number, __: string, ___: DeliveryPlanWorkType, ____: WorkloadType[], _____: number[]): Promise<getWorkloadResponse> {
    return new Promise((resolve) => {
      resolve({workloads: [], selectable_workloads: [], selectable_units: []});
    })
  }

  public async getAuthority():Promise<getAuthorityResponse> {
    return {}
  }
  public async getDrivingRecords(_: string):Promise<getDrivingRecordsResponse> {
    return {}
  }
  public async getActualVehicleRatioTransitions(_:number):Promise<getActualVehicleRatioTransitionsResponse> {
    return new Promise((resolve) => {
      resolve({} as getActualVehicleRatioTransitionsResponse);
    })
  }
  public async getSafetyDrivingScoreTransitions(_:number):Promise<getSafetyDrivingScoreTransitionsResponse> {
    return new Promise((resolve) => {
      resolve({} as getSafetyDrivingScoreTransitionsResponse);
    })
  }

  public async getRestraintAndWorkingTimes(_:number|null):Promise<getRestraintAndWorkingTimesResponse> {
    return new Promise((resolve) => {
      resolve({} as getRestraintAndWorkingTimesResponse);
    })
  }

  public async getSpots(request: getSpotRequest):Promise<getSpotResponse> {
    return new Promise((resolve) => {
      resolve({} as getSpotResponse);
    })
  }

  public async getOperationOfficeWithAssociation():Promise<getOperationOfficeWithAssociationResponse> {
    return new Promise((resolve) => {
      resolve({} as getOperationOfficeWithAssociationResponse);
    })
  }

  public async updateOperationOfficeWithAssociation(request: updateOperationOfficeRequest):Promise<getOperationOfficeWithAssociationResponse> {
    return new Promise((resolve) => {
      resolve({} as getOperationOfficeWithAssociationResponse);
    })
  }

  public async getManagemnetOfficeAccounts():Promise<getManagementOfficeAccountsResponse[]> {
    return new Promise((resolve) => {
      resolve([]);
    })
  }

  public async updateReportSettingsRequest(request: updateReportSettingsRequest):Promise<updateReportSettingsRequest> {
    return new Promise((resolve) => {
      resolve({} as updateReportSettingsRequest);
    })
  }

  public async getSelfAccount():Promise<SelfAccount> {
    return new Promise((resolve) => {
      resolve({} as SelfAccount);
    })
  }

  public async getSelfOffice():Promise<SelfOffice> {
    return new Promise((resolve) => {
      resolve({} as SelfOffice)
    })
  }

  public async getSelfRoles():Promise<SelfRole[]> {
    return new Promise((resolve) => {
      resolve([])
    })
  }

  public async getFirmwareList(request:getFirmwareListRequest): Promise<getFirmwareListResponse> {
    return new Promise((resolve) => {
      resolve({} as getFirmwareListResponse);
    })
  }

  public async autoCompleteFirmwaresVersions(): Promise<getFirmwareVersionsResponse> {
    return new Promise((resolve) => {
      resolve([] as getFirmwareVersionsResponse);
    })
  }

  public async getFirmware(request: getFirmwareRequest): Promise<getFirmwareResponse> {
    return Promise.resolve({} as getFirmwareResponse);
  }

  public async updateFirmware(request:updateFirmwareRequest): Promise<void> {
    return Promise.resolve();
  }

  public async createFirmware(request:createFirmwareRequest): Promise<void> {
    return Promise.resolve();
  }

  public async getDeviceFirmwareList(request:getDeviceFirmwareListRequest): Promise<getDeviceFirmwareListResponse> {
    return new Promise((resolve) => {
      resolve({} as getDeviceFirmwareListResponse);
    })
  }

  public async getDeviceIds(): Promise<geDeviceIdsResponse> {
    return new Promise((resolve) => {
      resolve([] as geDeviceIdsResponse);
    })
  }

  public async attachFirmwareToDevices(request:attachFirmwareToDevicesRequest): Promise<void> {
    return Promise.resolve();
  }

  public async detachFirmwareFromDevices(request:detachFirmwareFromDevicesRequest): Promise<void> {
    return Promise.resolve();
  }

  public async getOffices():Promise<getOfficesResponse[]> {
    return new Promise((resolve) => {
      resolve([])
    })
  }

  public async isAPIAuthorityInitialized():Promise<boolean> {
    return new Promise((resolve) => {
      resolve(true)
    })
  }

  public async getCvrUploads(params: getCvrUploadsType):Promise<getPagenatedCvrUploadResponse> {
    return new Promise((resolve) => {
      resolve({} as getPagenatedCvrUploadResponse)
    })
  }

  public async getCvrUploadPresignedUrl(job_id: string):Promise<string|null> {
    return new Promise((resolve) => {
      resolve('')
    })
  }

  public async createCvrUpload(params: createCvrUploadRequest):Promise<getCvrUploadResponse> {
    return new Promise((resolve) => {
      resolve({} as getCvrUploadResponse)
    })
  }
  public async updateCvrUploadHold(id: number, hold: boolean):Promise<getCvrUploadHoldResponse> {
    return new Promise((resolve) => {
      resolve({} as getCvrUploadHoldResponse)
    })
  }
  public async removeCvrUpload(id: number):Promise<CvrUpload> {
    return new Promise((resolve) => {
      resolve({} as CvrUpload)
    })
  }
  public async cancelCvrUpload(id: number):Promise<CvrUpload> {
    return new Promise((resolve) => {
      resolve({} as CvrUpload)
    })
  }

  public async downloadCvrUpload(params: getCvrUploadResponse):Promise<boolean> {
    return new Promise((resolve) => {
      resolve(true);
    })
  }

  public async createOrUpdateDeliveryPlanWorkingTimes(workingTimes: createOrUpdateDeliveryPlanWorkingTimeRequest[]): Promise<void> {
    return new Promise((resolve) => {
      resolve()
    })
  }

  public async getNotices(): Promise<getNoticesResponse> {
    return new Promise((resolve) => {
      resolve({} as getNoticesResponse)
    })
  }

  public async upsertNoticeWatchedTime(watchedAt: string): Promise<void> {
    return new Promise((resolve) => {
      resolve()
    })
  }

  public async getPaginatedDevices(_: getPaginatedDevicesRequest):Promise<getPaginatedDevicesResponse> {
    return new Promise((resolve) => {
      resolve({} as getPaginatedDevicesResponse);
    })
  }
  public async getDevice(id: number): Promise<getDeviceResponse> {
    return new Promise((resolve) => {
      resolve({} as getDeviceResponse)
    })
  }
  public async createOrUpdateDevice(id: number | null, request: createOrUpdateDevice): Promise<getDeviceResponse> {
    return new Promise((resolve) => {
      resolve({} as getDeviceResponse)
    })
  }
  public async updateDeviceStatus(id: number, request: updateStatus): Promise<getPaginatedDevicesResponse> {
    return new Promise((resolve) => {
      resolve({} as getPaginatedDevicesResponse)
    })
  }
  public async getMakers(): Promise<getMakersResponse> {
    return new Promise((resolve) => {
      resolve({} as getMakersResponse)
    })
  }
  public async getAllowAccessOffices():Promise<getAllowAccessOfficeResponse[]> {
    return new Promise((resolve) => {
      resolve({} as getAllowAccessOfficeResponse[])
    })
  }
  public async getAllowAccessOffice(id: number):Promise<getAllowAccessOfficeResponse> {
    return new Promise((resolve) => {
      resolve({} as getAllowAccessOfficeResponse)
    })
  }
  public async createAllowAccessOffice(request: createAllowAccessOffice):Promise<getAllowAccessOfficeResponse> {
    return new Promise((resolve) => {
      resolve({} as getAllowAccessOfficeResponse);
    })
  }
  public async deleteAllowAccessOffice(id: number):Promise<getAllowAccessOfficeResponse> {
    return new Promise((resolve) => {
      resolve({} as getAllowAccessOfficeResponse);
    })
  }
  public async getCvrUploadPeriods(deviceId: number):Promise<CvrUploadPeriod[] | null> {
    return new Promise((resolve) => {
      resolve({} as CvrUploadPeriod[]);
    })
  }
  public async getTemperatureInformationSpot(request: temperatureInformationSpotRequest):Promise<temperatureInformationSpotResponse[]> {
    return new Promise((resolve) => {
      resolve({} as temperatureInformationSpotResponse[]);
    })
  }
  public async getLatestSensors(request: latestSensorsRequest):Promise<latestSensorsResponse> {
    return new Promise((resolve) => {
      resolve({} as latestSensorsResponse);
    })
  }
  public async searchSubManagementOffice(param: getSubManagementOffice):Promise<getPagenatedSubManagementOffice> {
    return new Promise((resolve) => {
      resolve({} as getPagenatedSubManagementOffice);
    })
  }
  public async getSubManagementOffice(id: number):Promise<subManagementOffice> {
    return new Promise((resolve) => {
      resolve({} as subManagementOffice);
    })
  }
  public async updateSubManagementOfficeStatus(id: number, request: updateStatus):Promise<boolean> {
    return new Promise((resolve) => {
      resolve(true);
    })
  }
  public async createOrUpdateSubManagementOffice(id: null|number, request: createOrUpdateSubManagementOffice):Promise<subManagementOffice> {
    return new Promise((resolve) => {
      resolve({} as subManagementOffice);
    })
  }

  public async getCvrUploadSummaries(request: getCvrUploadSummariesRequest): Promise<getCvrUploadSummariesResponse | cvrUploadSummariesData[]> {
    return new Promise((resolve) => {
      resolve({} as getCvrUploadSummariesResponse);
    })
  }
  public async getUnexpectedEvents(request: any): Promise<getUnexpectedEvents> {
    return new Promise((resolve) => {
      resolve({} as getUnexpectedEvents);
    })
  }
  public async getUnexpectedEvent(id: number): Promise<getUnexpectedEvent> {
    return new Promise((resolve) => {
      resolve({} as getUnexpectedEvent);
    })
  }
  public async createOrUpdateUnexpectedEvent(request: FormData, id: string | null | number): Promise<getUnexpectedEvent> {
    return new Promise((resolve) => {
      resolve({} as getUnexpectedEvent);
    })
  }
  public async getUnexpectedEventAndLongTimeDrivingExtensions(request: getUnexpectedEventAndLongTimeDrivingExtensions): Promise<getPagenatedUnexpectedEventAndLongTimeDrivingExtensions> {
    return new Promise((resolve) => {
      resolve({} as getPagenatedUnexpectedEventAndLongTimeDrivingExtensions);
    })
  }
  public async unexpectedEventFileDownload(filePath: string): Promise<boolean> {
    return new Promise((resolve) => {
      resolve({} as boolean)
    })
  }
  public async deleteUnexpectedEvent(id: number): Promise<boolean> {
    return new Promise((resolve) => {
      resolve({} as boolean)
    })
  }
  public async getRoles(): Promise<getRoles> {
    return new Promise((resolve) => {
      resolve({} as getRoles)
    })
  }
  public async getRole(id: number): Promise<getRole> {
    return new Promise((resolve) => {
      resolve({} as getRole)
    })
  }
  public async createOrUpdateRole(id: number, request: createOrUpdateRole): Promise<getRole> {
    return new Promise((resolve) => {
      resolve({} as getRole)
    })
  }
  public async getRoleAssignAccounts(request: getRoleAssignAccountsRequest): Promise<getRoleAssignAccountsResponse> {
    return new Promise((resolve) => {
      resolve({} as getRoleAssignAccountsResponse)
    })
  }
  public async getDepartments(request?: getDepartment): Promise<getPagenateDepartment> {
    return new Promise((resolve) => {
      resolve({} as getPagenateDepartment);
    })
  }
  public async getDepartment(id: number): Promise<getDepartmentAllParams> {
    return new Promise((resolve) => {
      resolve({} as getDepartmentAllParams);
    })
  }
  public async createOrUpdateDepartment(id: number | null, request: createOrUpdateDepartment): Promise<getDepartmentAllParams> {
    return new Promise((resolve) => {
      resolve({} as getDepartmentAllParams);
    })
  }
  public async deleteDepartment(id: number): Promise<boolean> {
    return new Promise((resolve) => {
      resolve({} as boolean);
    })
  }
  public async getPermissionList(params: getPermissionListRequestType): Promise<getPermissionListResponseType>{
    return new Promise((resolve) => {
      resolve({} as getPermissionListResponseType)
    })
  }
  public async getRoleTemplates(): Promise<getRoleTemplateResponseType[]>{
    return new Promise((resolve) => {
      resolve({} as getRoleTemplateResponseType[])
    })
  }
  public async getUserRoles(searchParams: getUserRolesRequestType): Promise<getPaginateUserRolesResponseType> {
    return new Promise((resolve) => {
      resolve({} as getPaginateUserRolesResponseType)
    })
  }
  public async getUserRoleForm(id: number): Promise<getUserRoleFormResponseType> {
    return new Promise((resolve) => {
      resolve({} as getUserRoleFormResponseType)
    })
  }
  public async createUserRoleForm(param: upsertUserRoleFormRequestType): Promise<getUserRoleFormResponseType> {
    return new Promise((resolve) => {
      resolve({} as getUserRoleFormResponseType)
    })
  }
  public async updateUserRoleForm(id: number, param: upsertUserRoleFormRequestType): Promise<getUserRoleFormResponseType> {
    return new Promise((resolve) => {
      resolve({} as getUserRoleFormResponseType)
    })
  }
  public async deleteUserRoleForm(id: number): Promise<{status: boolean}> {
    return new Promise((resolve) => {
      resolve({} as {status: boolean})
    })
  }

  public async getRoleAssignedAccounts(id: number, params: getRoleAssinedAccountsRequest): Promise<getRoleAssignedAccountResponseType[]> {
    return new Promise((resolve) => {
      resolve({} as getRoleAssignedAccountResponseType[])
    })
  }
}

export default DummyApiProvider
