export default {
  charts: {
    title: "温度管理",
    chartTitle: "温度管理表",
    outputTime: "出力時間",
    label: {
      '1h': "1時間",
      '3h': "3時間",
      '12h': "12時間",
      '1d': "1日",
      custom: "カスタム",
      driving: "走行",
      stopping: "停止",
      loading: "荷積",
      unloading: "荷卸"
    },
    modal: {
      specifyPeriod: "期間を指定",
      specifyStartDate: "開始日時を指定",
      selectPeriod: "期間を選択してください。(最大７日間)",
      selectStartDate: "開始日時を選択してください。(最大7日間)",
      cancel: "キャンセル",
      select: "選択",
      currentTime: "現在時刻",
    },
    errors: {
      download: "ダウンロードに失敗しました。",
      getData: "データが取得できませんでした。",
      notDeviceRegistered: "車両にデバイスが登録されていません。",
      selectWithin7Days: "7日間以内で選択してください。",
      inputStartAndEnd: "開始日時と終了日時を正しく入力してください。"
    },
    button: {
      search: "検索",
      pdfGraph: "グラフ出力",
      pdfList: "数値データ出力",
      apply: "適用"
    }
  },
  informationNumerical: {
    title: "温度情報数値確認",
    list: {
      header: {
        department: "組織",
        vehicle: "車番",
        temperature: "温度",
        dateTime: "取得時間",
        sopt: "取得場所",
        ch1: "CH1",
        ch2: "CH2",
        ch3: "CH3",
        ch4: "CH4",
      }
    }
  }
}
