<template>
  <div class="d-flex justify-content-end bd-highlight mb-3 output-button-area">
    <v-btn
      class="exec-btn"
      :disabled="!downloadable"
      @click="handleClickOutputPdf"
      :style="{
        backgroundColor : downloading ? '#B3B8BB' : ''
      }"
    >
      {{downloadBtnLabel}}
    </v-btn>
  </div>
</template>

<script>
  import Api from "@/services/api/ApiServiceFabrick";
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import i18n from '@/lang/i18n';

  export default {
    setup() {
      const { loginWithRedirect } = useAuth();
      return {
        loginWithRedirect,
        i18n
      }
    },
    props: {
      showFlash: {
        type: Function
      },
      operationOfficeId: null,
      operationOfficeName: null,
      groupName: null,
      monthly: null,
      driverOrVehicleIds: {},
      outputSettings: {},
    },
    data() {
      return {
        downloading: false,
        summaryOrNoticeExceeding: {
          summary: "Summary\\",
          noticeExceeding: "NoticeExceeding\\",
        },
      }
    },
    computed: {
      downloadable() {
        const ids = this.driverOrVehicleIds['ids']
        return ids?.length && !this.downloading
      },
      downloadBtnLabel() {
        return this.downloading ?
          i18n.global.t('pages.monthlyReport.button.downloading') :
          i18n.global.t('pages.monthlyReport.button.download');
      },
    },
    methods: {
      handleClickOutputPdf() {
        this.downloading = true
        const params = this.makeCreateFileRequest()
        Api().createJobStatus(params).then((data) => {
          this.pollingJobStatus(data.id)
        }).catch((error) => {
          const res = error.response
          switch (res.status) {
            case 401:
              this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
              break
            case 403:
              location.reload()
              break
            case 422:
          }
          this.downloading = false
        })
      },
      pollingJobStatus(id) {
        const interval = setInterval(() => {
          Api().getJobStatus(id).then((res) => {
            if(res.status == "completed") {
              this.download(res.file_path)
              clearInterval(interval)
              this.downloading = false
            }
            if (res.status == "failed") {
              let message = res.output_contents.message
              if (res.output_contents.detail_message) {
                message += "\n\n" + res.output_contents.detail_message
                this.showFlash(message, 'error', false)
              } else {
                alert(message)
              }
              clearInterval(interval)
              this.downloading = false
            }
          })
        }, 3000)
      },
      download(path) {
        Api().fileDownload(path)
      },
      makeCreateFileRequest() {
        const params = {
          operation_office_id: this.operationOfficeId,
          operation_name: this.operationOfficeName,
          group_name: this.group_name,
          monthly: this.monthly,
        }
        return this.setIdsAndType(params)
      },
      setIdsAndType(params) {
        const driverIdsCount = this.driverOrVehicleIds["driver_ids"].length
        const idsCount = this.driverOrVehicleIds["ids"].length

        const prefixName = 'App\\Services\\OutputReports\\Monthly\\'
        const targetAggregation = driverIdsCount ? "Drivers\\" : "Vehicles\\"
        const outputFormat = this.outputSettings?.outputFormat?.data
        const serviceName = "CreateService"

        let reportType = this.summaryOrNoticeExceeding[this.outputSettings?.reportType?.data]
        if (!reportType && (1 < driverIdsCount || 1 < idsCount)) reportType = "Multiple\\"
        if (!reportType) reportType = "Individual\\"

        params["type"] = prefixName + targetAggregation + reportType + outputFormat + serviceName

        if (driverIdsCount) {
          if (reportType == "Individual\\") {
            params["driver_id"] = this.driverOrVehicleIds["driver_ids"][0]
            params["id"] = this.driverOrVehicleIds["ids"][0]
          } else {
            params["drivers"] = this.driverOrVehicleIds["ids"]
          }
        }
        if (!driverIdsCount) {
          if (reportType == "Individual\\") {
            params["vehicle_id"] = this.driverOrVehicleIds["ids"][0]
          } else {
            params["vehicles"] = this.driverOrVehicleIds["ids"]
          }
        }

        return params
      },
    },
  }

</script>

<style>
  .output-button-area .v-btn--disabled.v-btn--variant-contained {
    background-color: rgb(71, 170, 196) !important;
  }
</style>
