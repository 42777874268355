export default {
  title: "安全運転帳票",
  documentTitle: "安全運転帳票出力",
  displayCurrentMonth: "当月を表示しています",
  outputConditions: "出力条件",
  monthly: "月分",
  list: {
    driverName: "ドライバー"
  },
  button: {
    download: "ダウンロード",
    downloading: "ダウンロード中..."
  },
  outputLayout: {
    ledger: "帳票",
    driverOrder: "ドライバー並び順",
    safeDrivingActualResult: "安全運転実績",
    safeDrivingReport: "安全運転レポート",
    fuelEconomy: "燃費",
    drivingScore: "運転スコア",
    emphasisMonthlyDrivingScore: "強調設定 月次運転スコア",
    emphasisDailyDrivingScore: "強調設定 日次運転スコア",
    emphasisMonthlyFuelEconomy: "強調設定 月次燃費",
    emphasisDailyFuelEconomy: "強調設定 日次燃費",
    emphasisScore: "強調点数",
    less: "以下",
    unitLess: "km/L以下",
    pointLess: "点以下",
    registerDate: "登録日",
    fuelEconomyDesc: "燃費上位順",
    fuelEconomyAsc: "燃費下位順",
    drivingScoreDesc: "スコア上位順",
    drivingScoreAsc: "スコア下位順"
  }
}
