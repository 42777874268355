<template>
  <div class="monthly-report-list">
    <template v-if="notReady"></template>
    <template v-else-if="!searched && !notReady && !isOperationOffice && !isDepartment">
      <div class="not-searched">
        <label >
          {{ i18n.global.t('pages.common.list.runFilter') }}
        </label>
      </div>
    </template>
    <template v-if="listLoading">
      <div class="not-searched">
        <label>
          {{ i18n.global.t('pages.common.list.reading') }}
        </label>
      </div>
    </template>
    <v-table>
      <thead>
        <tr class="table-header-row">
          <th style="width: 20%">
            <div class="checkbox-in-grid align-middle">
            <v-checkbox
              outlined hide-details="false"
              v-model="allSelect"
              @change="toggleSelectAll"
            />
            </div>
          </th>
          <th class="text-subtitle-1" style="font-weight: bold">{{ showHeaderTitle() }}</th>
            <th class="text-subtitle-1" style="font-weight: bold">
              <div v-show="showableEditWorkingTimeItem()">
                {{ i18n.global.t('pages.monthlyReport.list.inputWorkingTimes') }}
              </div>
            </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="items?.length != 0">
        <tr
          v-for="item in items"
          :key="item.id"
        >
          <td>
            <v-checkbox
              class="align-middle checkbox-in-grid"
              label=''
              v-model="selectStatuses[`${item.id}`]"
              @change="toggleSelect"
            />
          </td>
          <td class="text-subtitle-2">{{ showDriverNameOrVehicleNumberPlate(item) }}</td>
            <td>
              <div class="d-flex" v-if="item.driver_id">
                <button @click="moveToWorkingTimesSetting(item)" class="material-symbols-outlined">
                  edit_note
                </button>
              </div>
            </td>
        </tr>
        </template>
        <template v-else-if="searched && !listLoading">
          <tr>
            <td colspan="2">
              {{ i18n.global.t('pages.common.list.noData') }}
            </td>
          </tr>
        </template>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import i18n from '@/lang/i18n';

  export default {
    setup() {
      return {
        i18n
      }
    },
    props: {
      searched: null,
      operationOfficeId: null,
      monthly: null,
      items: [],
      listLoading: {
        type: Boolean,
        default: false
      },
      isOperationOffice: {
        type: Boolean,
        default: false
      },
      isDepartment: {
        type: Boolean
      },
      notReady: {
        type: Boolean,
        default: false
      },
      editableWorkingTime: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        allSelect: false,
        selectStatuses: {},
        nameList: {
          all: i18n.global.t('pages.monthlyReport.list.all'),
          driver: i18n.global.t('pages.monthlyReport.driver'),
          vehicle: i18n.global.t('pages.monthlyReport.vehicle'),
        },
        workingTimesSettingUrl: '/monthly-report/working-times/edit'
      }
    },
    computed: {
    },
    methods: {
      toggleSelectAll() {
        Object.keys(this.selectStatuses).forEach((key) => {
          this.selectStatuses[key] = this.allSelect
        })
        this.onChange()
      },
      toggleSelect() {
        this.allSelect = false
        this.onChange()
      },
      onChange() {
        const selected = { driver_ids: [], ids: [] }
        this.items.forEach((item) => {
          if (this.selectStatuses[item.id]) {
            if (item.driver_id) selected['driver_ids'].push(item.driver_id)
            selected['ids'].push(item.id)
          }
        })
        this.$emit('select', selected)
      },
      showDriverNameOrVehicleNumberPlate(item) {
        if (item.driver_id) return item.last_name + ' ' + item.first_name
        return item.number_plate
      },
      showHeaderTitle() {
        if (!this.items?.length) return this.nameList['all']
        if (this.items[0].driver_id) return this.nameList['driver']
        return this.nameList['vehicle']
      },
      showableEditWorkingTimeItem() {
        return (this.showHeaderTitle() == this.nameList['driver']) && this.editableWorkingTime;
      },
      moveToWorkingTimesSetting(item) {
        const queryParams = '?operation_office_id=' + this.operationOfficeId + '&account_id=' + item.id + '&monthly=' + this.monthly;

        window.open(this.workingTimesSettingUrl + queryParams, '_blank');
      }
    },
    watch: {
      items: {
        handler() {
          this.selectStatuses = {}
          this.items.map((v) => {
            this.selectStatuses[v['id']] = false
          })
          this.allSelect = false
          this.onChange()
        }
      },
    },
  }

</script>

<style>
.monthly-report-list {
  position: relative;
}

.monthly-report-list .v-table__wrapper{
  max-height: 34em;
  min-height: 20vw;
  overflow: auto;
}
.monthly-report-list .v-table__wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.monthly-report-list .v-table__wrapper::-webkit-scrollbar-thumb {
  background-color: rgb(150,150,150);
  border-radius: 5px;
  box-shadow: inset 3px 3px 3px rgba(255,255,255,0.2);
}
.monthly-report-list .v-table__wrapper::-webkit-scrollbar-track {
  background-color: rgb(220,220,220);
  border-radius: 5px;
  box-shadow: inset 3px -3px 5px rgba(0,0,0,0.2);
}
.monthly-report-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
}
.monthly-report-list table .table-header-row th {
  z-index: 1;
}
.monthly-report-list table .table-header-row th:first-child {
  z-index: 2;
}
.checkbox-in-grid :first-child {
  height: inherit !important;
}
.checkbox-in-grid {
  padding: 1vm;
  display: inline-grid !important;
  transform: scale(1.2);
}
.not-searched {
  height: 100%;
  position: absolute;
  z-index: 1000;
  background-color: rgba(80,80,80,0.5);
  right: 0;
  left: 0;
}
.not-searched>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
</style>
