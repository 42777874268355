export default {
  title: "乗務員別労働実績明細表",
  list: {
    monthlyWorkTotal: "月間労働集計",
    alertSetting: "アラート設定",
    alertLvTime: "時間以上",
  },
  messages: {
    successSave: "保存しました。編集画面を閉じますか？",
  },
  errors: {
    input: "正しく入力してください。",
    updateData: "データが更新されています。リロードしますか？"
  },
  inputError: "入力値エラー",
  header: {
    restraintTimeByDay: "単日当たり拘束時間",
    restraintDays: "拘束日数",
    averageGoal: "平均/目標",
    actualAssumption: "実績/想定",
    totalTime: "合計時間",
    restraint: "拘束",
    workTime: "労働時間",
    mileage: "走行距離",
    driving: "運転",
    break: "休憩",
    loading: "荷積",
    unloading: "荷卸",
    shipping: "荷待",
    wait: "待機",
    others: "その他",
    rest: "休息",
    duplication: "重複",
    overTime: "時間外労働時間",
    lateNightWorkingHours: "深夜労働時間",
    date: "日付",
    dayOfWeek: "曜日",
    startWork: "始業",
    endWork: "終業",
    drivingTotalTime: "運行総時間",
    betweenEndAndStart: "終業 ~ 始業時間",
    restraintTime: "拘束時間",
    duplicationTime: "重複時間",
    restPeriod: "休息期間",
    sum: "計",
    start: "開始",
    end: "終了",
    total: "合計"
  },
  trip: {
    year: "年",
    month: "月",
    day: "日",
    hour: "時",
    minute: "分",
    between: "から",
    inputbetweenDate: "までの日時を入力してください",
    inputBeforeTime: "以前の時間を入力してください",
    inputAfterDate: "以降の日時を入力してください"
  }
}
