<template>
  <div :class="colClass" v-if="isShow">
    <v-switch
        class="pt-2"
        :class="isSwitchOn ? 'switch-on' : ''"
        v-model="value"
        :disabled="disabled"
        :false-value="initialType === 'ONOFF' ? 'OFF' : '0'"
        :true-value="initialType === 'ONOFF' ? 'ON' : '1'"
        :error-messages="errors"
    >
      <template v-slot:label>
        {{ label }}
        <span v-if="isRequired" style="color: red">
          <strong>*</strong>
        </span>
      </template>
    </v-switch>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    modelValue: {
      type: String,
      default: '0',
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    colClass: {
      type: String,
      default: 'col-6',
    },
  },
  data() {
    return {
      value: this.modelValue,
      initialType: this.getInitialType(this.modelValue),
    };
  },
  computed: {
    isSwitchOn() {
      return this.value === '1' || this.value === 'ON';
    }
  },
  methods: {
    getInitialType(value) {
      return value === 'ON' || value === 'OFF' ? 'ONOFF' : 'NUMERIC';
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    modelValue: {
      handler(newValue) {
        this.value = newValue;
        this.initialType = this.getInitialType(newValue);
      },
      immediate: true,
    },
  },
};
</script>

<style>
.switch-on .v-switch__track {
  background-color: #0088a6 !important;
}
</style>
