<template>
  <div class="monthly-report-output-layout pt-3 pb-3">
    <v-row class="mb-1">
      <v-col>
        <v-select
          class="small"
          v-model="selectedReportType"
          :items="displayReportTypes"
          :label="i18n.global.t('pages.monthlyReport.outputLayout.ledger')"
          :disabled="!existDriverOrVehicleList"
          :clearable="false"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-select
          class="small"
          v-model="selectedOutputFormat"
          :items="displayOutputFormats"
          :label="i18n.global.t('pages.monthlyReport.outputLayout.format')"
          :clearable="false"
        >
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import i18n from '@/lang/i18n';

export default {
  setup() {
    return {
      i18n
    }
  },
  props: {
    aggregation: {
      type: String,
      default: () => 'driver',
    },
    existDriverOrVehicleList: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      displayReportTypes: [],
      displayOutputFormats: [],
      reportTypes: {
        driver: [
          {
            key: 'driverReport1',
            label: i18n.global.t('pages.monthlyReport.outputLayout.byDriverPreview'),
            data: ''
          },
          {
            key: 'driverReport2',
            label: i18n.global.t('pages.monthlyReport.outputLayout.laborSummaryTable'),
            data: 'summary',
          },
          {
            key: 'driverReport3',
            label: i18n.global.t('pages.monthlyReport.outputLayout.noticeExceeding'),
            data: 'noticeExceeding',
          },
        ],
        vehicle: [
          {
            key: 'vehicleReport1',
            label: i18n.global.t('pages.monthlyReport.outputLayout.byVehiclePreview'),
            data: ''
          },
          {
            key: 'vehicleReport2',
            label: i18n.global.t('pages.monthlyReport.outputLayout.vehicleSummaryTable'),
            data: 'summary',
          },
        ],
      },
      outputFormats: [
        { key: 'pdf', label: 'PDF', data: 'Pdf' },
        { key: 'csv', label: 'CSV', data: 'Csv' },
      ],
      selectedReportType: null,
      selectedOutputFormat: null,
    };
  },
  created() {
    this.outputSettings = {
      reportType: this.reportTypes['driver'][0],
      outputFormat: this.outputFormats[0],
    };
    (this.selectedReportType = i18n.global.t('pages.monthlyReport.outputLayout.byDriverPreview')),
      (this.selectedOutputFormat = 'PDF');
    this.setDisplayReportTypes();
    this.setDisplayOutputFormats();
  },
  methods: {
    setDisplayReportTypes() {
      this.displayReportTypes = this.reportTypes[this.aggregation].map((v) => {
        return v.label;
      });
    },
    setDisplayOutputFormats() {
      this.displayOutputFormats = this.outputFormats.map((v) => {
        return v.label;
      });
    },
    updateOutputSettings(key, obj) {
      this.outputSettings[key] = obj;
      this.$emit('change', this.outputSettings);
    },
  },
  watch: {
    aggregation: {
      handler() {
        this.selectedReportType =
          this.reportTypes[this.aggregation][0]['label'];
        this.setDisplayReportTypes();
      },
    },
    selectedReportType: {
      handler() {
        const selectedReportType = this.reportTypes[this.aggregation].find(
          (v) => v.label == this.selectedReportType
        );
        this.updateOutputSettings('reportType', selectedReportType);
      },
    },
    selectedOutputFormat: {
      handler() {
        const selectedOutputFormat = this.outputFormats.find(
          (v) => v.label == this.selectedOutputFormat
        );
        this.updateOutputSettings('outputFormat', selectedOutputFormat);
      },
    },
  },
};
</script>

<style>
.monthly-report-output-layout .v-checkbox * {
  height: 2em;
}
.monthly-report-output-layout .v-col {
  padding-top: 0;
  padding-bottom: 0.5em;
}

.monthly-report-output-layout label,
.monthly-report-output-layout input {
  font-size: 12px;
}

.monthly-report-output-layout i,
.monthly-report-output-layout .v-field__append-inner i {
  font-size: 24px !important;
}

.chart-display label {
  font-size: 14px;
}
</style>
