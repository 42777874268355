<template>
  <div :class="searchAreaClass">
    <h4 class="d-flex justify-content-between mt-0">
      {{ title }}
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fit=""
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
        sandboxuid="0"
        @click="toggleSidebar"
      >
        <template v-if="hide">
          <path
            d="M7.615 11.795l4.59-4.59-1.41-1.41-6 6 6 6 1.41-1.41-4.59-4.59zm9.59 6v-12h-2v12h2z"
            fill-rule="evenodd"
            sandboxuid="0"
          ></path>
        </template>
        <template v-if="!hide">
          <path
            d="M6 7.41L10.438 12 6 16.59 7.363 18l5.802-6-5.802-6L6 7.41zM16.066 6H18v12h-1.934V6z"
            sandboxuid="0"
          />
        </template>
      </svg>
    </h4>
    <div :class="`strpied-tabled-with-hover ${hide ? 'd-none' : ''}`">
      <div class="d-grid gap-2">
        <ManagementOfficeAutoComplete
          v-model="form.managementOffice"
          :error-messages="errors.managementOffice"
          selectFirstIfOnlyOne
          :clearable="managementOfficeClearable"
        />
        <SingleOperationOfficeAutoComplete
          v-model="form.operationOffice"
          :managementOffice="form.managementOffice"
          :error-messages="errors.operationOffice"
          selectFirstIfOnlyOne
          :clearable="operationOfficeClearable"
        />
        <GroupAutoComplete
          v-model="form.group"
          :managementOffice="form.managementOffice"
          :operationOffices="form.operationOffice ? [form.operationOffice] : []"
          :type="groupType"
          :error-messages="errors.group"
        />
        <DepartmentAutoComplete
          v-model="form.department"
          :operationOffices="form.operationOffice ? [form.operationOffice] : []"
          :error-messages="errors.department"
          :selectFirstIfOnlyOne="isDepartment"
        />
        <v-select
          v-model="form.targetOfAggregation"
          :items="displayAggregations"
          :label="i18n.global.t('pages.monthlyReport.searchForm.aggregationTarget')"
          :error="errors.targetOfAggregation"
          :clearable="false"
        >
        </v-select>
        <div
          class="ml-2 v-input__details"
          v-if="errors.targetOfAggregation"
          :class="errors.targetOfAggregation ? 'error' : ''"
        >
          <div class="ml-3 v-messages">
            <div class="v-messages__message">
              {{ this.errors.targetOfAggregation }}
            </div>
          </div>
        </div>
        <VehicleAutoComplete
          v-if="form.targetOfAggregation == aggregations['vehicle']"
          v-model="form.vehicle"
          :selectedDepartment="form.department"
          :management-office="form.managementOffice"
          :operationOffices="form.operationOffice ? [form.operationOffice] : []"
          :error-messages="errors.vehicle"
        />
        <DriverAutoComplete
          v-if="form.targetOfAggregation == aggregations['driver']"
          v-model="form.driver"
          :selectedDepartment="form.department"
          :operationOffices="form.operationOffice ? [form.operationOffice] : []"
          :error-messages="errors.driver"
        />
        <div class="m-2">
          <span class="small">
            {{ i18n.global.t('pages.monthlyReport.searchForm.monthly') }}
          </span>
          <Datepicker
            locale="jp"
            class=""
            v-model="form.selectYearMonth"
            format="yyyy/MM"
            monthPicker="true"
            auto-apply
            :close-on-auto-apply="true"
          />
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="col-md-3">
          <svg
            version="1.2"
            style="overflow: visible; width: 299px; height: 1px; opacity: 1; mix-blend-mode: normal; rotate(0deg);"
          >
            <defs>
              <path
                id="path-1650848226989195"
                d="M1126 703 C1126 703 1425 703 1425 703"
              ></path>
            </defs>
            <g transform="translate(-1126, -703)">
              <path
                style="
                  stroke: rgb(194, 186, 186);
                  stroke-width: 1;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  fill: none;
                "
                d="M1126 703 C1126 703 1425 703 1425 703"
              ></path>
            </g>
          </svg>
        </div>
        <div class="d-flex search-buttons">
          <v-btn
            class="ma-2 exec-btn"
            @click="handleClickSearch"
            :style="{
              backgroundColor: loading ? '#B3B8BB' : '',
            }"
          >
            {{ searchBtnLabel }}
          </v-btn>
          <v-btn class="ma-2 reset-btn" @click="clearForm">
            {{ i18n.global.t('pages.common.button.reset') }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManagementOfficeAutoComplete from '@/components/AutoCompletes/ManagementOfficeAutoComplete';
import SingleOperationOfficeAutoComplete from '@/components/AutoCompletes/SingleOperationOfficeAutoComplete';
import GroupAutoComplete from '@/components/AutoCompletes/GroupAutoComplete';
import DepartmentAutoComplete from '@/components/AutoCompletes/DepartmentAutoComplete';
import VehicleAutoComplete from '@/components/AutoCompletes/VehicleAutoComplete';
import DriverAutoComplete from '@/components/AutoCompletes/DriverAutoComplete';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import i18n from '@/lang/i18n';

export default {
  setup() {
    return {
      i18n
    }
  },
  props: {
    loading: {},
    isOperationOffice: {
      type: Boolean,
      default: false,
    },
    isSubManagementOffice: {
      type: Boolean,
      default: false,
    },
    isManagementOffice: {
      type: Boolean,
      default: false,
    },
    isDepartment: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      aggregations: {
        driver: i18n.global.t('pages.monthlyReport.driver'),
        vehicle: i18n.global.t('pages.monthlyReport.vehicle')
      },
      form: {
        selectYearMonth: this.getThisYearMoth(),
        targetOfAggregation: i18n.global.t('pages.monthlyReport.driver'),
        driver: null,
        vehicle: null,
      },
      searchBtnLabel: i18n.global.t('pages.common.button.exec'),
      hide: false,
      errors: {},
    };
  },
  computed: {
    searchAreaClass() {
      return this.hide ? 'search-area-hide p-4' : 'search-area p-4';
    },
    title() {
      return this.hide ? '' : i18n.global.t('pages.common.filter.title');
    },
    displayAggregations() {
      return Object.values(this.aggregations);
    },
    groupType() {
      const reversalAggregations = {};
      Object.keys(this.aggregations).forEach((aggregationKey) => {
        const aggregationValue = this.aggregations[aggregationKey];
        reversalAggregations[aggregationValue] = aggregationKey;
      });

      return reversalAggregations[this.form.targetOfAggregation];
    },
    managementOfficeClearable() {
      return this.isManagementOffice;
    },
    operationOfficeClearable() {
      return this.isManagementOffice || this.isSubManagementOffice;
    },
  },
  methods: {
    changeSearchButton() {
      this.searchBtnLabel = this.loading ?
      i18n.global.t('pages.common.button.loading') :
      i18n.global.t('pages.common.button.exec');
    },
    handleClickSearch() {
      if (this.validateForm()) {
        this.$emit('search', this.form);
      }
    },
    clearForm() {
      this.form = {
        managementOffice: this.managementOfficeClearable ? undefined : this.form.managementOffice,
        operationOffice: this.operationOfficeClearable ? undefined : this.form.operationOffice,
        selectYearMonth: this.getThisYearMoth(),
        targetOfAggregation: this.aggregations['driver'],
      };
    },
    toggleSidebar() {
      this.hide = !this.hide;
    },
    validateForm() {
      this.errors = {};
      let ret = true;
      if (!this.form.operationOffice) {
        this.errors.operationOffice = [i18n.global.t('pages.common.filter.errorMssages.pleaseSelect')];
        ret = false;
      }
      if (!this.form.targetOfAggregation) {
        this.errors.targetOfAggregation = i18n.global.t('pages.common.filter.errorMssages.pleaseSelect');
        ret = false;
      }
      return ret;
    },
    getThisYearMoth() {
      const today = new Date();
      return { year: today.getFullYear(), month: today.getMonth() };
    },
  },
  watch: {
    form: {
      handler() {
        if (this.form.targetOfAggregation == this.aggregations['driver'])
          this.form.vehicle = null;
        if (this.form.targetOfAggregation == this.aggregations['vehicle'])
          this.form.driver = null;
        this.$emit('formchange', this.form);
      },
      deep: true,
    },
    loading: {
      handler() {
        this.changeSearchButton();
      },
    },
  },
  components: {
    ManagementOfficeAutoComplete,
    SingleOperationOfficeAutoComplete,
    GroupAutoComplete,
    DepartmentAutoComplete,
    VehicleAutoComplete,
    DriverAutoComplete,
    Datepicker,
  },
};
</script>
