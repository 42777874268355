<template>
  <div class="d-flex h-100 align-items-center">
    <div class="content p-4 w-100 justify-content-center text-center text-danger" >
      <h1 class="fw-bold text-danger m-0 pt-5 pb-1">404</h1>
      <h2 class="text-danger m-0 pb-5">Not Found</h2>
    </div>
  </div>
</template>

<script lang="ts">
</script>
