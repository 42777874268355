<script setup>
import vLoaderAnimation from '@/components/template/v-loader-animation.vue';
import { reactive, ref, onUnmounted, watch, nextTick, computed } from 'vue';
import { format, parse } from 'date-fns';
import { useAuth } from '@/services/user/Auth0UserProvider';
import Api from '@/services/api/ApiServiceFabrick';
import RealTimeCharts from '@/components/TemperatureSensorChartParts/RealTimeCharts';
import CustomModal from '@/components/TemperatureSensorChartParts/CustomModal';
import TimeCanvas from '@/components/TemperatureSensorChartParts/TimeCanvas';
import { SENSOR_CHART_STEP_SCALE, SENSOR_CHART_STEP_SCALE_CHANGE_THRESHOLD } from "@/services/consts";
import i18n from '@/lang/i18n';

const props = defineProps({
  updateViewConditions: Function,
  showFlash: Function,
  initialViewChartConditions: Object,
  selectedVehicle: Object,
  isQueryParams: Boolean,
  sensorSettings: Object,
});

const emits = defineEmits([
  'selectOutputTime',
  'loadingState',
  'pdfBtnShoweableState',
  'labels',
]);
let viewConditions = reactive(props.initialViewChartConditions);
const outputType = ref('12h');
const isVehicle = ref(false);
const vehicle = reactive({
  operationOfficeId: Number,
  vehicleId: Number,
});
const outputContents = reactive({
  vehicleId: Number,
  carNumber: String,
  drivingTimes: [],
  stoppingTimes: [],
  loadingTimes: [],
  unloadingTimes: [],
  sensors: Array,
});
const cutDrivingTimes = reactive({ data: [] });
const cutStoppingTimes = reactive({ data: [] });
const cutLoadingTimes = reactive({ data: [] });
const cutUnloadingTimes = reactive({ data: [] });
const baseSensorDatas = ref([]);
let sensorDatas = reactive({
  unit1_channel1: [],
  unit2_channel1: [],
  unit3_channel1: [],
  unit4_channel1: [],
});
let unitStatus = reactive({
  date_time: [],
  unit1_status: [],
  unit2_status: [],
  unit3_status: [],
  unit4_status: [],
});
let displayUnitStatus = reactive({
  unit1_status: null,
  unit2_status: null,
  unit3_status: null,
  unit4_status: null,
});
const windowInnerWidth = ref(window.innerWidth);
const { loginWithRedirect } = useAuth();
const dateTimes = ref([]);
const endDateTime = ref();
const startDateTime = ref();
const isZoomed = ref(false);
const beforeSelectItem = ref('12h');
const modalState = ref(false);
const customValues = ref([]);
const chartWidth = ref(0);
const chartStartDateTime = ref();
const chartEndDateTime = ref();
const sensorsLoading = ref(false);
const polloingInterval = ref();
const loadingText = 'Loading...';
const type = 'App\\Services\\OutputReports\\Sensors\\PreviewCreateService';
const types = ['sensor', 'driving', 'stopping', 'loading', 'unloading'];
const requestStartAndEndTime = reactive({});
const applyCustomValue = reactive({});
const getDataThresholdUnixTimes = reactive({});
const isAddDataGetting = ref(false);
const axisValue = ref([]);
const oneDayUnixTime = 24 * 60 * 60 * 1000;
const twoDaysUnixTime = 48 * 60 * 60 * 1000;
const customMaxLength = 168;
const defaultChartMin = -50;
const defaultChartMax = 30;
const formatedSensorDataMin = ref();

const isRelative = () => {
  return viewConditions.outputType == 'relative';
};
const isAbsolute = () => {
  return viewConditions.outputType == 'absolute';
};

const isCustom = () => {
  return isAbsolute() || isRelative();
};

watch(props.initialViewChartConditions, () => {
  viewConditions.outputType = props.initialViewChartConditions.outputType;
  customValues.value = viewConditions;
  outputType.value = isCustom() ? 'custom' : viewConditions.outputType;
  beforeSelectItem.value = outputType.value;
});

function getRequestRange() {
  (requestStartAndEndTime.startDateTime = new Date(
    new Date().getTime() - twoDaysUnixTime
  )),
    (requestStartAndEndTime.endDateTIme = new Date(
      new Date().getTime() + oneDayUnixTime
    ));
  if (isCustom()) {
    (requestStartAndEndTime.startDateTime = new Date(
      new Date(viewConditions.startTime).getTime() - oneDayUnixTime
    )),
      (requestStartAndEndTime.endDateTIme = new Date(
        new Date(viewConditions.endTime).getTime() + oneDayUnixTime
      ));
  }
  return {
    startTime: requestStartAndEndTime.startDateTime,
    endTime: requestStartAndEndTime.endDateTIme,
  };
}

function fetchData() {
  setViewConditions();
  const isResetData = true;

  const requestRange = getRequestRange();
  const request = {
    type: type,
    operation_office_id: vehicle.operationOfficeId,
    vehicle_id: vehicle.vehicleId,
    types: types,
    start_date_time: format(requestRange.startTime, 'yyyy-MM-dd HH:mm:ss'),
    end_date_time: format(requestRange.endTime, 'yyyy-MM-dd HH:mm:ss'),
  };
  createJobStatus(request, isResetData);
}

function createJobStatus(request, isResetData) {
  Api()
    .createJobStatus(request)
    .then((data) => {
      pollingJobStatus(data.id, isResetData, isCustom());
    })
    .catch((error) => {
      const res = error.response;
      switch (res.status) {
        case 401:
          loginWithRedirect({ appState: { targetUrl: location.pathname } });
          break;
        case 403:
          location.reload();
          props.showFlash(i18n.global.t('pages.common.messages.forbidden'), 'error', true);
          break;
        case 422:
          clearInterval(polloingInterval.value);
          sensorsLoading.value = false;
          props.showFlash(i18n.global.t('pages.temperatureSensors.charts.errors.getData'), 'error', true);
      }
    });
}

function pollingJobStatus(id, isResetData) {
  let requesting = false;
  const interval = setInterval(() => {
    if (requesting) return;
    requesting = true;
    Api()
      .getJobStatus(id)
      .then((res) => {
        requesting = false;
        if (res.output_contents == null) return;

        if (res.status == 'completed') {
          clearInterval(interval);

          if (isZoomed.value) {
            isAddDataGetting.value = false;
            addBeforeOrAfterData(res.output_contents);
            return;
          }
          baseSensorDatas.value = res.output_contents.sensors || [];
          makeChartDatas();

          sensorsLoading.value = false;
          emits('pdfBtnShoweableState', true);
          nextTick(() => {
            new Promise((resolve) => {
              setChartWidth();
              setChartStartEndDateTime();
              resolve();
            }).then(() => {
              outputContents.drivingTimes = concatData(
                outputContents.drivingTimes,
                res.output_contents.driving_times,
                isResetData
              );
              outputContents.stoppingTimes = concatData(
                outputContents.stoppingTimes,
                res.output_contents.stopping_times,
                isResetData
              );
              outputContents.loadingTimes = concatData(
                outputContents.loadingTimes,
                res.output_contents.loading_times,
                isResetData
              );
              outputContents.unloadingTimes = concatData(
                outputContents.unloadingTimes,
                res.output_contents.unloading_times,
                isResetData
              );

              convertToUnixTimeWithCutPerTimes();
            });
          });
        }
        if (res.status == 'failed') {
          clearInterval(interval);
        }
      });
  }, 1000);
}

function addBeforeOrAfterData(responseOutputContents) {
  makeAddData(responseOutputContents);

  nextTick(() => {
    new Promise((resolve) => {
      setChartWidth();
      setChartStartEndDateTime(axisValue.value);
      resolve();
    }).then(() => {
      if (
        new Date(responseOutputContents.start_time).getTime() <=
        getDataThresholdUnixTimes.startTime
      ) {
        outputContents.drivingTimes = concatData(
          responseOutputContents.driving_times,
          outputContents.drivingTimes,
          false
        );
        outputContents.stoppingTimes = concatData(
          responseOutputContents.stopping_times,
          outputContents.stoppingTimes,
          false
        );
        outputContents.loadingTimes = concatData(
          responseOutputContents.loading_times,
          outputContents.loadingTimes,
          false
        );
        outputContents.unloadingTimes = concatData(
          responseOutputContents.unloading_times,
          outputContents.unloadingTimes,
          false
        );
      } else {
        outputContents.drivingTimes = concatData(
          outputContents.drivingTimes,
          responseOutputContents.driving_times,
          false
        );
        outputContents.stoppingTimes = concatData(
          outputContents.stoppingTimes,
          responseOutputContents.stopping_times,
          false
        );
        outputContents.loadingTimes = concatData(
          outputContents.loadingTimes,
          responseOutputContents.loading_times,
          false
        );
        outputContents.unloadingTimes = concatData(
          outputContents.unloadingTimes,
          responseOutputContents.unloading_times,
          false
        );
      }

      convertToUnixTimeWithCutPerTimes();
    });
  });
}

function hoursBySelectOutputTime() {
  switch (outputType.value) {
    case '1h':
      return 1;
    case '3h':
      return 3;
    case '1d':
      return 24;
    case 'custom':
      return customMaxLength;
    default:
      return 12;
  }
}

function makeSliceStartAndEndTimes() {
  if (isAbsolute()) {
    const start = customValues.value.startTime
      ? new Date(customValues.value.startTime)
      : viewConditions.startTime;
    const end = customValues.value.endTime
      ? new Date(customValues.value.endTime)
      : viewConditions.endTime;

    return {
      startTime: start,
      endTime: end,
    };
  }
  const sliceTime = hoursBySelectOutputTime();
  let endTime = new Date();
  let startTime = new Date(endTime.getTime() - sliceTime * 60 * 60 * 1000);

  return {
    startTime,
    endTime,
  };
}

function concatData(baseTimes, times, isResetData) {
  if (isResetData) return times;

  return baseTimes.concat(times);
}

function convertToUnixTimeWithCut(times) {
  const startUnixTime = new Date(chartStartDateTime.value).getTime();
  const endUnixTime = new Date(chartEndDateTime.value).getTime();
  const ret = [];

  for (var i = 0; i < times.length; i++) {
    const time = times[i];
    const start = new Date(time['start']).getTime();
    const end = new Date(time['end']).getTime();

    if (end <= startUnixTime) continue;
    if (start >= endUnixTime) break;

    ret.push({
      start: Math.max(start, startUnixTime),
      end: Math.min(end, endUnixTime),
    });
  }

  return ret;
}

function setChartStartEndDateTime(xaxis = null) {
  setViewConditions();
  if (xaxis) {
    // ズーム時
    viewConditions.startTime = new Date(xaxis.min);
    viewConditions.endTime = new Date(xaxis.max);
    props.updateViewConditions(viewConditions);
  }
  chartStartDateTime.value = viewConditions.startTime;
  chartEndDateTime.value = viewConditions.endTime;
}

function setChartWidth() {
  if (document.getElementsByClassName('apexcharts-grid').length) {
    chartWidth.value = document
      .getElementsByClassName('apexcharts-grid')[0]
      .getBoundingClientRect().width;
  }
}

function makeAddData(responseOutputContents) {
  const chartDatas = {
    dateTimes: makeChartDateTimes(responseOutputContents.sensors),
    sensorDatas: makeSeriesDatas(responseOutputContents.sensors),
  };
  addData(responseOutputContents, chartDatas);

  displayUnitStatus = reactive(makeDisplayUnitStatus());
  makeThresholdTimes(
    dateTimes.value[0],
    dateTimes.value[dateTimes.value.length - 1]
  );
}

function addData(responseOutputContents, chartDatas) {
  if (
    new Date(responseOutputContents.start_time).getTime() <=
    getDataThresholdUnixTimes.startTime
  ) {
    dateTimes.value = concatData(chartDatas.dateTimes, dateTimes.value, false);
    makeSensorDatas(chartDatas.sensorDatas.seriesDatas, sensorDatas);
    addBatteryDatas(chartDatas.sensorDatas.batteryData, unitStatus);
    formatedSensorDataMin.value = makeFormatedMinValue(minValue(chartDatas.sensorDatas.seriesDatas));
    addStartEndDateDatas(requestStartAndEndTime.startDateTime, null);
  } else {
    dateTimes.value = concatData(dateTimes.value, chartDatas.dateTimes, false);
    makeSensorDatas(sensorDatas, chartDatas.sensorDatas.seriesDatas);
    addBatteryDatas(unitStatus, chartDatas.sensorDatas.batteryData);
    formatedSensorDataMin.value = makeFormatedMinValue(minValue(chartDatas.sensorDatas.seriesDatas));
    addStartEndDateDatas(null, requestStartAndEndTime.endDateTIme);
  }
}

function makeSensorDatas(baseData, addData) {
  sensorDatas.unit1_channel1 = concatData(
    baseData.unit1_channel1,
    addData.unit1_channel1,
    false
  );
  sensorDatas.unit2_channel1 = concatData(
    baseData.unit2_channel1,
    addData.unit2_channel1,
    false
  );
  sensorDatas.unit3_channel1 = concatData(
    baseData.unit3_channel1,
    addData.unit3_channel1,
    false
  );
  sensorDatas.unit4_channel1 = concatData(
    baseData.unit4_channel1,
    addData.unit4_channel1,
    false
  );
}

function addBatteryDatas(baseData, addData) {
  unitStatus.date_time = concatData(
    baseData.date_time,
    addData.date_time,
    false
  );
  unitStatus.unit1_status = concatData(
    baseData.unit1_status,
    addData.unit1_status,
    false
  );
  unitStatus.unit2_status = concatData(
    baseData.unit2_status,
    addData.unit2_status,
    false
  );
  unitStatus.unit3_status = concatData(
    baseData.unit3_status,
    addData.unit3_status,
    false
  );
  unitStatus.unit4_status = concatData(
    baseData.unit4_status,
    addData.unit4_status,
    false
  );
}

function makeChartDatas() {
  const chartDatas = {
    dateTimes: makeChartDateTimes(baseSensorDatas.value),
    sensorDatas: makeSeriesDatas(baseSensorDatas.value),
  };
  dateTimes.value = chartDatas.dateTimes || [];
  sensorDatas = reactive(chartDatas.sensorDatas.seriesDatas) || [];
  unitStatus = reactive(chartDatas.sensorDatas.batteryData) || [];
  formatedSensorDataMin.value = makeFormatedMinValue(minValue(chartDatas.sensorDatas.seriesDatas));
  addStartEndDateDatas(
    requestStartAndEndTime.startDateTime,
    requestStartAndEndTime.endDateTIme
  );

  displayUnitStatus = reactive(makeDisplayUnitStatus());
  makeThresholdTimes(
    dateTimes.value[0],
    dateTimes.value[dateTimes.value.length - 1]
  );
}

function makeChartDateTimes(sensors) {
  return sensors.map((sensor) => {
    return sensor.date_time;
  });
}

function makeSeriesDatas(sensors) {
  const seriesDatas = {
    unit1_channel1: [],
    unit2_channel1: [],
    unit3_channel1: [],
    unit4_channel1: [],
  };
  let batteryData = {
    date_time: [],
    unit1_status: [],
    unit2_status: [],
    unit3_status: [],
    unit4_status: [],
  };
  sensors.forEach((sensor) => {
    seriesDatas.unit1_channel1.push(sensor.unit1_channel1);
    seriesDatas.unit2_channel1.push(sensor.unit2_channel1);
    seriesDatas.unit3_channel1.push(sensor.unit3_channel1);
    seriesDatas.unit4_channel1.push(sensor.unit4_channel1);
    batteryData = makeBatteryData(batteryData, sensor);
  });
  return {
    seriesDatas: seriesDatas,
    batteryData: batteryData,
  };
}

function makeBatteryData(baseData, sensor) {
  baseData.date_time.push(new Date(sensor.date_time).getTime());
  baseData.unit1_status.push(sensor.unit1_status.substr(0, 4));
  baseData.unit2_status.push(sensor.unit2_status.substr(0, 4));
  baseData.unit3_status.push(sensor.unit3_status.substr(0, 4));
  baseData.unit4_status.push(sensor.unit4_status.substr(0, 4));

  return baseData;
}

const sensorDataMin = computed(() => {
  if (props.sensorSettings.min_scale != null) return props.sensorSettings.min_scale;
  if (!isNaN(formatedSensorDataMin.value)) return formatedSensorDataMin.value;

  return defaultChartMin;
})

const yaxisMin = computed(() => {
  return yaxisMax.value - (stepScale.value * chartTickAmount.value);
})

const yaxisMax = computed(() => {
  if (props.sensorSettings.max_scale != null) return props.sensorSettings.max_scale;
  if (!isNaN(formatedSensorDataMin.value)) return sensorDataMin.value + 75;

  return defaultChartMax;
})

const maxMinDiff = computed(() => {
  return yaxisMax.value - sensorDataMin.value;
})

const stepScale = computed(() => {
  let dividedValue = SENSOR_CHART_STEP_SCALE;
  if (maxMinDiff.value > SENSOR_CHART_STEP_SCALE_CHANGE_THRESHOLD) dividedValue = SENSOR_CHART_STEP_SCALE * 2;
  if (maxMinDiff.value > SENSOR_CHART_STEP_SCALE_CHANGE_THRESHOLD * 2) dividedValue = SENSOR_CHART_STEP_SCALE * 4;
  return dividedValue;
})

const chartTickAmount = computed(() => {
  return Math.ceil(maxMinDiff.value / stepScale.value);
})

function minValue(data) {
  return Math.min.apply(null, [
    Math.min.apply(null, data.unit1_channel1),
    Math.min.apply(null, data.unit2_channel1),
    Math.min.apply(null, data.unit3_channel1),
    Math.min.apply(null, data.unit4_channel1),
  ]);
}

function makeFormatedMinValue(value) {
  let formatedMinValue = value;
  if (value % SENSOR_CHART_STEP_SCALE != 0) {
    formatedMinValue = value - (value % SENSOR_CHART_STEP_SCALE);
    if (value < 0) formatedMinValue = value - (SENSOR_CHART_STEP_SCALE + (value % SENSOR_CHART_STEP_SCALE));
  }
  return Math.floor(formatedMinValue);
}

function addStartEndDateDatas(start, end) {
  addStartAndEndDateTime(start, end);
  addStartAndEndChartSeriesData(start, end);
}

function addStartAndEndDateTime(start = null, end = null) {
  if (start) {
    const startTime = format(new Date(start), 'yyyy-MM-dd HH:mm:ss');
    dateTimes.value.unshift(startTime);
  }

  if (end) {
    const endTime = format(new Date(end), 'yyyy-MM-dd HH:mm:ss');
    dateTimes.value.push(endTime);
  }
}

function addStartAndEndChartSeriesData(start = null, end = null) {
  if (start) {
    sensorDatas.unit1_channel1.unshift(null);
    sensorDatas.unit2_channel1.unshift(null);
    sensorDatas.unit3_channel1.unshift(null);
    sensorDatas.unit4_channel1.unshift(null);
  }

  if (end) {
    sensorDatas.unit1_channel1.push(null);
    sensorDatas.unit2_channel1.push(null);
    sensorDatas.unit3_channel1.push(null);
    sensorDatas.unit4_channel1.push(null);
  }
}

function makeDisplayUnitStatus() {
  const vieConditionStartUnixTime = new Date(
    viewConditions.startTime
  ).getTime();
  const vieConditionEndUnixTime = new Date(viewConditions.endTime).getTime();
  let diff = [];
  let index = 0;
  let isValue = false;

  unitStatus.date_time.forEach((value, i) => {
    if (vieConditionStartUnixTime > value || vieConditionEndUnixTime < value)
      return;
    diff[i] = Math.abs(vieConditionEndUnixTime - value);
    index = diff[index] < diff[i] ? index : i;
    isValue = true;
  });
  return {
    unit1_status: isValue ? unitStatus.unit1_status[index] : null,
    unit2_status: isValue ? unitStatus.unit2_status[index] : null,
    unit3_status: isValue ? unitStatus.unit3_status[index] : null,
    unit4_status: isValue ? unitStatus.unit4_status[index] : null,
  };
}

function makeThresholdTimes(startTime, endTime) {
  (getDataThresholdUnixTimes.startTime =
    new Date(startTime).getTime() + 12 * 60 * 60 * 1000),
    (getDataThresholdUnixTimes.endTIme =
      new Date(endTime).getTime() - 12 * 60 * 60 * 1000);
}

function zoom(bool, xaxis = null) {
  isZoomed.value = bool;
  axisValue.value = xaxis;
  if (xaxis && !isAddDataGetting.value) {
    decisionGetOrNotData(xaxis);
  }

  new Promise((resolve) => {
    setChartStartEndDateTime(xaxis);
    resolve();
  }).then(() => {
    convertToUnixTimeWithCutPerTimes();
  });
  displayUnitStatus = reactive(makeDisplayUnitStatus());
}

function decisionGetOrNotData(xaxis) {
  if (getDataThresholdUnixTimes.startTime > xaxis.min) {
    isAddDataGetting.value = true;
    createJobStatus(makeScrollRequest(xaxis.min, null));
  }
  if (getDataThresholdUnixTimes.endTIme < xaxis.max) {
    isAddDataGetting.value = true;
    createJobStatus(makeScrollRequest(null, xaxis.max));
  }
}

function makeScrollRequest(min = null, max = null) {
  const startTime = min
    ? new Date(new Date(dateTimes.value[0]).getTime() - oneDayUnixTime)
    : dateTimes.value[dateTimes.value.length - 1];
  const endTime = min
    ? dateTimes.value[0]
    : new Date(
        new Date(dateTimes.value[dateTimes.value.length - 1]).getTime() +
          oneDayUnixTime
      );

  requestStartAndEndTime.startDateTime = startTime;
  requestStartAndEndTime.endDateTIme = endTime;
  return {
    type: type,
    operation_office_id: vehicle.operationOfficeId,
    vehicle_id: vehicle.vehicleId,
    types: types,
    start_date_time: format(
      new Date(requestStartAndEndTime.startDateTime),
      'yyyy-MM-dd HH:mm:ss'
    ),
    end_date_time: format(
      new Date(requestStartAndEndTime.endDateTIme),
      'yyyy-MM-dd HH:mm:ss'
    ),
  };
}

function convertToUnixTimeWithCutPerTimes() {
  cutDrivingTimes.data = convertToUnixTimeWithCut(outputContents.drivingTimes);
  cutStoppingTimes.data = convertToUnixTimeWithCut(
    outputContents.stoppingTimes
  );
  cutLoadingTimes.data = convertToUnixTimeWithCut(outputContents.loadingTimes);
  cutUnloadingTimes.data = convertToUnixTimeWithCut(
    outputContents.unloadingTimes
  );
}

function resetSliceSensorData() {
  dateTimes.value = [];
  sensorDatas.unit1_channel1 = [];
  sensorDatas.unit2_channel1 = [];
  sensorDatas.unit3_channel1 = [];
  sensorDatas.unit4_channel1 = [];
}

function inactivateModalState(bool) {
  modalState.value = bool;
  outputType.value = beforeSelectItem.value;
}

function applyCustom(startAndEndTimes) {
  customValues.value = startAndEndTimes;
  applyCustomValue.outputType = startAndEndTimes.selectItem;
  applyCustomValue.startTime = startAndEndTimes.startTime;
  applyCustomValue.endTime = startAndEndTimes.endTime;

  outputType.value = 'custom';
  viewConditions.outputType = customValues.value.selectItem;
  viewConditions.startTime = new Date(customValues.value.startTime);
  viewConditions.endTime = new Date(customValues.value.endTime);

  initializeData();

  modalState.value = false;
  beforeSelectItem.value = outputType.value;
}

function onClickOutputTime(value) {
  isZoomed.value = false;
  if (value.target.nodeName == 'LABEL') return;
  const selectValue = value.target._value;
  if (selectValue == 'custom') return (modalState.value = true);
  outputType.value = selectValue;
  viewConditions.outputType = selectValue;

  if (beforeSelectItem.value == 'custom') {
    initializeData();
  }

  new Promise((resolve) => {
    setChartStartEndDateTime();
    resolve();
  }).then(() => {
    convertToUnixTimeWithCutPerTimes();
  });

  setViewConditions();
  makeChartDatas();
  beforeSelectItem.value = outputType.value;
}

function initializeData() {
  isZoomed.value = false;
  resetSliceSensorData();
  sensorsLoading.value = true;
  emits('pdfBtnShoweableState', false);
  fetchData();
}

function setViewConditions() {
  const range = makeSliceStartAndEndTimes();
  viewConditions = reactive({
    outputType: viewConditions.outputType,
    startTime: range.startTime,
    endTime: range.endTime,
  });
  if (isCustom() && !props.isQueryParams) {
    viewConditions = reactive({
      outputType: applyCustomValue.outputType,
      startTime: applyCustomValue.startTime,
      endTime: applyCustomValue.endTime,
    });
  }
  props.updateViewConditions(viewConditions);
}

function setOpacity() {
  if (sensorsLoading.value) return 'opacity: 0.3';
}
function changeChartLabels(values) {
  emits('labels', [values, displayUnitStatus, makeYaxisLabels()]);
}

function makeYaxisLabels() {
  const yaxisLabels = [];

  const apexchartsYaxisLabel = document.getElementsByClassName('apexcharts-yaxis-label');
  for(let i = 0; i < apexchartsYaxisLabel.length; i++) {
    yaxisLabels.unshift(Number(apexchartsYaxisLabel[i].children[1]['innerHTML']))
  }

  return yaxisLabels;
}

function vehicleChangeResetData() {
  resetSliceSensorData();
  cutDrivingTimes.data = [];
  cutStoppingTimes.data = [];
  cutLoadingTimes.data = [];
  cutUnloadingTimes.data = [];
  viewConditions.outputType = '12h';
  outputType.value = '12h';
}

function handleResize() {
  setChartWidth();
}
window.addEventListener('resize', handleResize);

watch(props.selectedVehicle, () => {
  if (props.selectedVehicle == null) isVehicle.value = false;
  vehicle.operationOfficeId = props.selectedVehicle.vehicle.operationOfficeId;
  vehicle.vehicleId = props.selectedVehicle.vehicle.id;
  clearInterval(polloingInterval.value);
  if (!props.isQueryParams) vehicleChangeResetData();

  if (props.selectedVehicle) {
    sensorsLoading.value = true;
    isVehicle.value = true;
    setViewConditions();
    initializeData();
    props.updateViewConditions(viewConditions);
    polloingInterval.value = setInterval(() => {
      if (isZoomed.value || isCustom()) return;
      fetchData();
    }, 150000);
    onUnmounted(() => {
      clearInterval(polloingInterval.value);
    });
  }
});

watch(sensorsLoading, (bool) => {
  emits('loadingState', bool);
});
</script>

<template>
  <div class="my-2 mr-3 d-flex justify-center">
    <div class="d-flex align-center">
      <div class="mt-4">
        <h5>
          {{ i18n.global.t('pages.temperatureSensors.charts.outputTime') }}
        </h5>
      </div>
      <v-radio-group
        inline
        v-model="outputType"
        :disabled="sensorsLoading || !dateTimes.length"
        @click="onClickOutputTime"
      >
        <v-radio
          :label="i18n.global.t('pages.temperatureSensors.charts.label.1h')"
          value="1h"
          color="blue"
        />
        <v-radio
          :label="i18n.global.t('pages.temperatureSensors.charts.label.3h')"
          value="3h"
          color="blue"
        />
        <v-radio
          :label="i18n.global.t('pages.temperatureSensors.charts.label.12h')"
          value="12h"
          color="blue"
        />
        <v-radio
          :label="i18n.global.t('pages.temperatureSensors.charts.label.1d')"
          value="1d"
          color="blue"
        />
        <v-radio
          :label="i18n.global.t('pages.temperatureSensors.charts.label.custom')"
          value="custom"
          color="blue"
        />
      </v-radio-group>
    </div>
  </div>

  <div v-if="isVehicle" :style="setOpacity()">
    <RealTimeCharts
      ref="realTimeChartsRef"
      :sensorDatas="sensorDatas"
      :dateTimes="dateTimes"
      :yaxisMin="yaxisMin"
      :yaxisMax="yaxisMax"
      :tickAmount="chartTickAmount"
      :isLoading="sensorsLoading"
      :width="chartWidth"
      :viewConditions="viewConditions"
      :displayUnitStatus="displayUnitStatus"
      @resize="setChartWidth"
      @zoomed="zoom"
      @chartLabels="changeChartLabels"
    />
    <div v-show="chartWidth > 0" id="times-area" class="mb-6 ml-10 pr-9">
      <TimeCanvas
        :title="i18n.global.t('pages.temperatureSensors.charts.label.driving')"
        :times="cutDrivingTimes.data"
        :startTime="chartStartDateTime"
        :endTime="chartEndDateTime"
        :width="chartWidth"
        @resize="setChartWidth"
      />
      <TimeCanvas
        :title="i18n.global.t('pages.temperatureSensors.charts.label.stopping')"
        :times="cutStoppingTimes.data"
        :startTime="chartStartDateTime"
        :endTime="chartEndDateTime"
        :width="chartWidth"
        @resize="setChartWidth"
      />
      <TimeCanvas
        :title="i18n.global.t('pages.temperatureSensors.charts.label.loading')"
        :times="cutLoadingTimes.data"
        :startTime="chartStartDateTime"
        :endTime="chartEndDateTime"
        :width="chartWidth"
        @resize="setChartWidth"
      />
      <TimeCanvas
        :title="i18n.global.t('pages.temperatureSensors.charts.label.unloading')"
        :times="cutUnloadingTimes.data"
        :startTime="chartStartDateTime"
        :endTime="chartEndDateTime"
        :width="chartWidth"
        @resize="setChartWidth"
      />
    </div>
  </div>
  <div
    v-show="sensorsLoading"
    class="loading"
    style="position: absolute; left: 45%; top: 25%"
  >
    <div
      class="d-flex flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
    >
      <div class="max-w-md w-full space-y-8">
        <v-loader-animation
          :text="'text-black'"
          :loading-text-style="'text-black text-lg'"
          :loading-text="loadingText"
          :addCharacterImg="false"
        />
      </div>
    </div>
  </div>

  <CustomModal
    title="カスタム"
    uploadBtnClass="exec-btn mt-0 ml-1"
    closeBtnClass="mt-0"
    :modalState="modalState"
    :startDateTime="viewConditions.startTime"
    :endDateTime="viewConditions.endTime"
    @apply="applyCustom"
    @closeModal="inactivateModalState"
  />
</template>
