export default {
  page: {
    documentTitle: "24時間毎データプレビュー"
  },
  preview: {
    title: "運行記録表",
    driverName: "乗務員",
    carNumber: "車番",
    leavingWarehouse: "記録開始",
    returningWarehouse: "記録終了",
    totalOperationTime: "記録時間",
    totalMileage: "運行距離",
    drivingTime: "走行時間",
    maxContinuousDrivingTime: "最大連続走行時間",
    maximumSpeed: "最高速度",
    deliveryDate: "運行日付",
    deliveryStartTime: "表示開始時刻",
    outputTime: "出力時間",
    speed: "速度",
    speedUnit: "[km/h]",
    distance: "距離",
    distanceUnit: "[km]",
    rotationSpeed: "回転数",
    rotationSpeedUnit: "[rpm]",
    noData: "データがありません。",
    returnPage: "戻る",
  },
  outputTimeList: {
    fourMinutes: "4分",
    twelveMinutes: "12分",
    eigntHours: "8時間",
    twentyFourHours: "24時間"
  }
}
