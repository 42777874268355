import i18n from '@/lang/i18n';
const COMMON_TABLE_CLASS = "border-sm border-opacity-50 text-center text-grey-darken-3"
const CHART_DETAIL_TABLE_CLASS = "border-surface border-opacity-100 border-md text-center bg-grey-lighten-1 text-grey-darken-3";
const CHART_COMMON_CLASS = "mx-auto d-flex mb-6";

export const EVERY_24_HOUR_REPORT_PREVIEW_TYPE = "App\\Services\\OutputReports\\Every24hours\\PreviewCreateService";
export const EVERY_24_HOUR_REPORT_TYPE = "App\\Services\\OutputReports\\Every24hours\\PdfCreateService";

export const TITLE_HEADERS = [
  {
    isTitle: true,
    keyName: "",
    labelPath: "pages.every24HoursReport.preview.title",
    class: "col-6 border-sm border-opacity-100 text-center bg-grey-darken-3 font-weight-bold"
  },
  {
    isTitle: false,
    keyName: "",
    labelPath: "pages.every24HoursReport.preview.driverName",
    class: `${COMMON_TABLE_CLASS} col-1 font-weight-bold`
  },
  {
    isTitle: false,
    keyName: "driver_name",
    labelPath: "",
    class: `${COMMON_TABLE_CLASS} col-2`
  },
  {
    isTitle: false,
    keyName: "",
    labelPath: "pages.every24HoursReport.preview.carNumber",
    class: `${COMMON_TABLE_CLASS} col-1 font-weight-bold`
  },
  {
    isTitle: false,
    keyName: "car_number",
    labelPath: "",
    class: `${COMMON_TABLE_CLASS} col-2`
  },
];

export const DRIVING_DETAIL_HEADERS = [
  {
    isTitle: false,
    keyName: "",
    labelPath: 'pages.every24HoursReport.preview.leavingWarehouse',
    class: `${COMMON_TABLE_CLASS} col-2  font-weight-bold`,
  },
  {
    isTitle: false,
    keyName: "",
    labelPath: 'pages.every24HoursReport.preview.returningWarehouse',
    class: `${COMMON_TABLE_CLASS} col-2  font-weight-bold`,
  },
  {
    isTitle: false,
    keyName: "",
    labelPath: 'pages.every24HoursReport.preview.totalOperationTime',
    class: `${COMMON_TABLE_CLASS} col-1  font-weight-bold`,
  },
  {
    isTitle: false,
    keyName: "",
    labelPath: 'pages.every24HoursReport.preview.totalMileage',
    class: `${COMMON_TABLE_CLASS} col-2  font-weight-bold`,
  },
  {
    isTitle: false,
    keyName: "",
    labelPath: 'pages.every24HoursReport.preview.drivingTime',
    class: `${COMMON_TABLE_CLASS} col-1  font-weight-bold`,
  },
  {
    isTitle: false,
    keyName: "",
    labelPath: 'pages.every24HoursReport.preview.maxContinuousDrivingTime',
    class: `${COMMON_TABLE_CLASS} col-2  font-weight-bold`,
  },
  {
    isTitle: false,
    keyName: "",
    labelPath: 'pages.every24HoursReport.preview.maximumSpeed',
    class: `${COMMON_TABLE_CLASS} col-2  font-weight-bold`,
  },
];

export const DRIVING_DETAIL_ROWS = [
  {
    id: "start-time-",
    dataName: "data-start-time",
    dataKey: "leaving_warehouse",
    class: COMMON_TABLE_CLASS,
    isConvertToJson: false
  },
  {
    id: "end-time-",
    dataName: "data-end-time",
    dataKey: "returning_warehouse",
    class: COMMON_TABLE_CLASS,
    isConvertToJson: false
  },
  {
    id: "",
    dataName: "",
    dataKey: "total_operation_time",
    class: COMMON_TABLE_CLASS,
    isConvertToJson: false
  },
  {
    id: "",
    dataName: "d",
    dataKey: "total_mileage",
    class: COMMON_TABLE_CLASS,
    isConvertToJson: false
  },
  {
    id: "",
    dataName: "",
    dataKey: "total_driving_time",
    class: COMMON_TABLE_CLASS,
    isConvertToJson: false
  },
  {
    id: "",
    dataName: "",
    dataKey: "max_continuous_driving_time",
    class: COMMON_TABLE_CLASS,
    isConvertToJson: false
  },
  {
    id: "",
    dataName: "",
    dataKey: "maximum_speed",
    class: COMMON_TABLE_CLASS,
    isConvertToJson: false
  },
];

export const DEFAULT_OUTPUT_TIME = "24h";
export const OUTPUT_TIME_LIST = {
  "four-minute": {
    label: i18n.global.t('pages.every24HoursReport.outputTimeList.fourMinutes'),
    value: "4min"
  },
  "twelve-minute": {
    label: i18n.global.t('pages.every24HoursReport.outputTimeList.twelveMinutes'),
    value: "12min"
  },
  "eight-hour": {
    label: i18n.global.t('pages.every24HoursReport.outputTimeList.eigntHours'),
    value: "8h"
  },
  "twenty-four-hours": {
    label: i18n.global.t('pages.every24HoursReport.outputTimeList.twentyFourHours'),
    value: DEFAULT_OUTPUT_TIME
  }
};

export const CHART_DETAIL_TABLE_HEADERS = [
  {
    isTitle: false,
    keyName: "",
    labelPath: 'pages.every24HoursReport.preview.deliveryDate',
    class: `${CHART_DETAIL_TABLE_CLASS} font-weight-bold`,
  },
  {
    isTitle: false,
    keyName: "",
    labelPath: 'pages.every24HoursReport.preview.deliveryStartTime',
    class: `${CHART_DETAIL_TABLE_CLASS} font-weight-bold`,
  },
  {
    isTitle: false,
    keyName: "",
    labelPath: 'pages.every24HoursReport.preview.totalMileage',
    class: `${CHART_DETAIL_TABLE_CLASS} font-weight-bold`,
  },
  {
    isTitle: false,
    keyName: "",
    labelPath: 'pages.every24HoursReport.preview.drivingTime',
    class: `${CHART_DETAIL_TABLE_CLASS} font-weight-bold`,
  },
];

export const CHART_DETAIL_TABLE_ROWS = [
  {
    id: "date-at-output-time-",
    dataName: "",
    dataKey: "",
    class: CHART_DETAIL_TABLE_CLASS,
    isConvertToJson: false
  },
  {
    id: "start-time-at-output-time-",
    dataName: "",
    dataKey: "",
    class: CHART_DETAIL_TABLE_CLASS,
    isConvertToJson: false
  },
  {
    id: "distance-at-output-time-",
    dataName: "",
    dataKey: "",
    class: CHART_DETAIL_TABLE_CLASS,
    isConvertToJson: false
  },
  {
    id: "driving-time-at-output-time-",
    dataName: "data-driving-times",
    dataKey: "devided_driving_section",
    class: CHART_DETAIL_TABLE_CLASS,
    isConvertToJson: true
  },
]

export const CHART_ITEMS = [
  {
    id: "speed-chart-region-",
    chartId: "speed-chart-",
    class: `${CHART_COMMON_CLASS} speed-chart`,
    style: "width: 85%;",
    chartHeight: "height: 30vh",
    yAxisLabel: {
      firsrRow: "pages.every24HoursReport.preview.speed",
      secondRow: "pages.every24HoursReport.preview.speedUnit",
      class: "d-flex align-items-center bg-grey-darken-3 text-white p-3 rounded",
      style: "width: 70px;",
    },
    chartCanvasClass: "w-100",
    dataName: "data-speed-value-date-times",
    dataKey: "speeds",
    sliderName: "speed-chart-slider-",
  },
  {
    id: "distance-chart-region-",
    chartId: "distance-chart-",
    class: `${CHART_COMMON_CLASS} distance-chart`,
    style: "width: 85%;",
    chartHeight: "height: 20vh",
    yAxisLabel: {
      firsrRow: "pages.every24HoursReport.preview.distance",
      secondRow: "pages.every24HoursReport.preview.distanceUnit",
      class: "d-flex align-items-center bg-grey-darken-3 text-white p-3 rounded",
      style: "width: 70px;",
    },
    chartCanvasClass: "w-100",
    dataName: "data-distance-value-date-times",
    dataKey: "distances",
    sliderName: "distance-chart-slider-",
  },
  {
    id: "rotation-speed-chart-region-",
    chartId: "rotation-speed-chart-",
    class: `${CHART_COMMON_CLASS} rotation-speed-chart`,
    style: "width: 85%;",
    chartHeight: "height: 20vh",
    yAxisLabel: {
      firsrRow: "pages.every24HoursReport.preview.rotationSpeed",
      secondRow: "pages.every24HoursReport.preview.rotationSpeedUnit",
      class: "d-flex align-items-center bg-grey-darken-3 text-white p-3 rounded",
      style: "width: 70px;",
    },
    chartCanvasClass: "w-100",
    dataName: "data-rotation-speed-value-date-times",
    dataKey: "rotation_speeds",
    sliderName: "rotation-speed-chart-slider-",
  },
]
